import React, { useEffect } from "react";
import SideBar from "./SideBar";
import NavBar from "../../components/NavBar/NavBar";
import { useDispatch, useSelector } from "react-redux";
import MainLoader from "../Loading/MainLoader";
import "./SellerDashboard.scss";
import {
  deleteSellerProduct,
  fetchSellerProducts,
} from "../../slices/sellerSlice";
import toast from "react-hot-toast";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";

const SellerProduct = () => {
  const seller = useSelector((state) => state.seller);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSellerProductsData();
  }, []);

  const fetchSellerProductsData = async () => {
    try {
      const resp = await dispatch(fetchSellerProducts());

      if (!resp) {
        toast.error("Somewhere went wrong");
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteHandler = async (id) => {
    try {
      const { payload } = await dispatch(deleteSellerProduct({ id }));

      if (payload) {
        fetchSellerProductsData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="seller-main-cont">
      {seller.status === "loading" && <MainLoader />}
      <NavBar />
      <SideBar />
      <div className="seller-product-list-cont">
        <h3>Seller Product List</h3>
        {/* List of products */}
        {seller?.fetchProducts?.products &&
          seller?.fetchProducts?.products.map((item, index) => (
            <div className="product-list-card" key={index}>
              <div>
                <img src={item?.images[0]} alt={item?.title} />
                <div className="product-list-card-data">
                  <h4>{item?.title}</h4>
                  <p>
                    <span>Price:</span>
                    {item?.pricePerDay}Rs/day
                  </p>
                  <p>
                    <span>Availability:</span>{" "}
                    {item?.availability ? "Avaliable" : "Unavaliable"}
                  </p>
                  <p>
                    <span>Category:</span> {item?.category}
                  </p>
                  <p>
                    <span>Address:</span> {item?.location?.address}
                  </p>
                </div>
              </div>

              <div className="product-list-card-btns">
                <MdEdit />
                <MdDeleteForever onClick={() => deleteHandler(item._id)} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SellerProduct;
