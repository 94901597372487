import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "..";
import toast from "react-hot-toast";

const initialState = {
  addProduct: {},
  fetchProducts: {},
  fetchOrder: {},
};

export const sellerAddProduct = createAsyncThunk(
  "seller/sellerAddProduct",
  async ({
    name,
    price,
    avaliable,
    desc,
    address,
    category,
    condition,
    images,
  }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Please login again", { duration: 2000 });
      return "Pleae Login again";
    }
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };
      const location = {
        type: "Point",
        coordinates: [25.560122622589724, 85.27013497303369],
        address: address,
      };
      console.log(images);
      const formData = new FormData();
      formData.append("title", name);
      formData.append("description", desc);
      formData.append("category", category);
      formData.append("pricePerDay", price);
      formData.append("availability", avaliable.toLowerCase() === "true");
      formData.append("rentalTerms", condition);
      images.forEach((image) => {
        formData.append("files", image); // Ensure each file is added
      });
      formData.append("location", JSON.stringify(location));

      const { data } = await api.post("/seller/product/add", formData, {
        headers: headers,
      });

      if (data) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      return false;
    }
  }
);

export const fetchSellerProducts = createAsyncThunk(
  "seller/fetchSellerProducts",
  async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Please login again", { duration: 2000 });
        return "Pleae Login again";
      }
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const { data } = await api.get("/seller/product/fetch", {
        headers: headers,
      });
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      return false;
    }
  }
);

export const fetchSellerOrders = createAsyncThunk(
  "seller/fetchSellerOrders",
  async ({ page }) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Please login again", { duration: 2000 });
        return "Pleae Login again";
      }
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const { data } = await api.get(`/seller/order/fetch?page=${page || 1}`, {
        headers: headers,
      });
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      return false;
    }
  }
);

export const deleteSellerProduct = createAsyncThunk(
  "seller/deleteSellerProduct",
  async ({ id }) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Please login again", { duration: 2000 });
        return "Pleae Login again";
      }
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const { data } = await api.delete(`/seller/product/delete/${id}`, {
        headers: headers,
      });

      toast.success(data?.message);
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      return false;
    }
  }
);

export const fetchSellerDashboard = createAsyncThunk(
  "seller/fetchSellerDashboard",
  async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Please login again", { duration: 2000 });
        return "Pleae Login again";
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const { data } = await api.get(`/seller/order/dashboard`, {
        headers: headers,
      });

      return data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      return false;
    }
  }
);

export const sellerSlice = createSlice({
  name: "seller",
  initialState,
  reducers: {
    setFetchProducts: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder

      //seller add product
      .addCase(sellerAddProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sellerAddProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.addProduct = action.payload;
      })
      .addCase(sellerAddProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //seller fetch product
      .addCase(fetchSellerProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSellerProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fetchProducts = action.payload;
      })
      .addCase(fetchSellerProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //seller fetch order
      .addCase(fetchSellerOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSellerOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fetchOrder = action.payload;
      })
      .addCase(fetchSellerOrders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //fetch seller dashboard
      .addCase(fetchSellerDashboard.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSellerDashboard.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fetchOrder = action.payload;
      })
      .addCase(fetchSellerDashboard.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })


      //delete product
      .addCase(deleteSellerProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSellerProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteSellerProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default sellerSlice.reducer;
