import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "..";

const initialState = {
  product: {},
  productById: {},
};

//fetching product
export const fetchProduct = createAsyncThunk(
  "product/fetchProduct",
  async ({ lat, lon, range, category, minPrice, maxPrice }) => {

    console.log(lat, lon)
    try {
      const { data } = await api.get(
        `/fetch/product/all?lat=${lat}&lon=${lon}&page=1&range=${range}&category=${category}&minPrice=${minPrice}&maxPrice=${maxPrice}`
      );
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

//fetching product by id
export const fetchProductById = createAsyncThunk(
  "product/fetchProductById",
  async ({ id }) => {
    try {
      const { data } = await api.get(`/fetch/product/${id}`);
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

//Slice definition for product state
export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //fetch product
      .addCase(fetchProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.product = action.payload;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //fetch product by id
      .addCase(fetchProductById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.productById = action.payload;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default productSlice.reducer;
