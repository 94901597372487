import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "..";
import toast from "react-hot-toast";

const initialState = {
  user: {},
  location: {
    lat: "",
    lon: "",
  },
};

//register user
export const registerUser = createAsyncThunk(
  "user/registerUser", // Changed action type here
  async ({ name, email, password }) => {
    const fcmId = localStorage.getItem("fcmToken");
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const { data } = await api.post(
        "/auth/register",
        { name, email, password, fcmId },
        { headers: headers }
      );

      if (data) {
        localStorage.setItem("userId", data?.user?._id);
        toast.success(data?.message);
        toast.success("OTP sent successfully");
      }
      return true;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      return false;
    }
  }
);

//google login user
export const googleLoginUser = createAsyncThunk(
  "user/googleLoginUser",
  async ({ code }) => {
    const fcmId = localStorage.getItem("fcmToken");
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    const { data } = await api.post(
      `/google/auth?code=${code}`,
      { fcmId },
      { headers }
    );
    if (data) {
      localStorage.setItem("token", data && data?.token);
    }
    return data;
  }
);

//login user
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async ({ email, password }) => {
    const fcmId = localStorage.getItem("fcmToken");
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const { data } = await api.post(
        "/auth/login",
        { email, password, fcmId },
        { headers }
      );

      if (data) {
        localStorage.setItem("token", data?.token);
        toast.success(data?.message);
      }

      return true;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      return false;
    }
  }
);

//logout user
export const logoutUser = createAsyncThunk("user/logoutUser", async () => {
  localStorage.removeItem("token");
  return;
});

//verify OTP
export const verifyOtp = createAsyncThunk("user/verifyOtp", async ({ otp }) => {
  try {
    let uesrId = localStorage.getItem("userId");
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const { data } = await api.post(
      "/auth/user/verify",
      { id: uesrId, otp: otp },
      { headers }
    );

    if (data) {
      toast.success(data?.message);
      localStorage.removeItem("userId");
    }
    return true;
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.message);
    return false;
  }
});

//fetching user
export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    toast.error("Please login again", { duration: 2000 });
    return "Pleae Login again";
  }

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  try {
    const { data } = await api.get("/user/profile", { headers });
    return data;
  } catch (error) {
    localStorage.removeItem("token");
    return error.response.data;
  }
});

//Slice definition for user state
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLocation: (state, action) => {
      state.location = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      //google login user
      .addCase(googleLoginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(googleLoginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(googleLoginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //login user
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //register user
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //logout user
      .addCase(logoutUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = "succeeded";
        state.user = initialState;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //fetch user
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // OTP verification cases
      .addCase(verifyOtp.pending, (state) => {
        state.otpStatus = "loading";
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.otpStatus = "succeeded";
        state.user = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.otpStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
