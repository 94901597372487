import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import productReducer from "./slices/productSlice";
import sellerReducer from "./slices/sellerSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    product: productReducer,
    seller: sellerReducer
  },
});

export default store;
