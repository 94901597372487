import React from "react";
import "./Pagination.scss";

const Pagination = ({ page, setPage, totalPages, pages }) => {

  const nextPageHandler = ()=>{
    setPage(page + 1);
  }
  const prevPageHandler = ()=>{
    setPage(page - 1);
  }
  return (
    <div className="pagination-cont">
      <div className={`pagination-box ${pages === 1 ? "disabled" : ""}`} onClick={prevPageHandler}>
        Prev
      </div>

      {Array.from({ length: totalPages }, (_, index) => (
        <div
          key={index + 1}
          className={`pagination-box ${pages === index + 1 ? "active" : ""}`}
          onClick={() => setPage(index + 1)}
        >
          {index + 1}
        </div>
      ))}
      <div
        className={`pagination-box ${pages === totalPages ? "disabled" : ""}`}
        onClick={nextPageHandler}
      >
        Next
      </div>
    </div>
  );
};

export default Pagination;
