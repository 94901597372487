import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/home/Home";
import NotFound from "./pages/notfound/NotFound";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Auth from "./pages/auth/Auth";
import User from "./pages/User/User";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, userSlice } from "./slices/userSlice";
import Product from "./pages/products/Product";
import ProductById from "./pages/product/ProductById";
import toast from "react-hot-toast";
import { requestNotificationPermission } from "./firebase";
import SellerDashboard from "./pages/seller/SellerDashboard";
import MainLoader from "./pages/Loading/MainLoader";
import SellerOrder from "./pages/seller/SellerOrder";
import SellerProduct from "./pages/seller/SellerProduct";
import SellerAddProduct from "./pages/seller/SellerAddProduct";

// Function to check if a user is authenticated
const isAuthenticated = () => {
  return !!localStorage.getItem("token");
};

const useIsSeller = () => {
  const { user, status } = useSelector((state) => state.user);

  if (status === "loading") {
    return null;
  }

  return user && user?.user?.isSeller;
};

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/auth" />;
};

const SellerRoute = ({ element }) => {
  let isSeller = useIsSeller();
  if (isSeller === null || isSeller === undefined) {
    return <MainLoader />;
  }

  return isAuthenticated() && isSeller ? element : <Navigate to="/" />;
};
// Wrapper for redirecting authenticated users away from the auth page
const PublicRoute = ({ element }) => {
  return isAuthenticated() ? <Navigate to="/" /> : element;
};

const SuperPublicRoute = ({ element }) => {
  return element;
};

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    fetchFcmToken();
  }, []);


  const fetchUserData = async () => {
    dispatch(fetchUser());
  };

  navigator.geolocation.getCurrentPosition(
    (data) => {
      dispatch(
        userSlice.actions.setUserLocation({
          lat: data.coords.latitude,
          lon: data.coords.longitude,
        })
      );
    },
    (error) => toast.error(error?.message, { duration: 2000 })
  );


  //fetching fcm token
  const fetchFcmToken = async () => {
    const fcmToken = await requestNotificationPermission();
    localStorage.setItem("fcmToken", fcmToken);
  };
  return (
    <GoogleOAuthProvider clientId="46068134389-npcfg44p5811dqdvnh0bjfnu6879e3jf.apps.googleusercontent.com">
      <BrowserRouter>
        <Routes>
          {/* Public Route */}
          <Route path="/auth" element={<PublicRoute element={<Auth />} />} />

          {/* Super Public Route */}
          <Route path="/" element={<SuperPublicRoute element={<Home />} />} />
          <Route
            path="/products"
            element={<SuperPublicRoute element={<Product />} />}
          />
          <Route
            path="/product/:id"
            element={<SuperPublicRoute element={<ProductById />} />}
          />

          {/* Private Route */}
          <Route path="/user" element={<PrivateRoute element={<User />} />} />

          {/* seller router */}
          <Route
            path="/seller/dashboard"
            element={<SellerRoute element={<SellerDashboard />} />}
          />
          <Route
            path="/seller/orders"
            element={<SellerRoute element={<SellerOrder />} />}
          />
          <Route
            path="/seller/products"
            element={<SellerRoute element={<SellerProduct />} />}
          />
          <Route
            path="/seller/addproduct"
            element={<SellerRoute element={<SellerAddProduct />} />}
          />

          {/* 404 Page */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

export default App;
