import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import "./SellerDashboard.scss";
import SideBar from "./SideBar";
import MainLoader from "../Loading/MainLoader";
import { useDispatch, useSelector } from "react-redux";
import { fetchSellerDashboard } from "../../slices/sellerSlice";
import toast from "react-hot-toast";

const SellerDashboard = () => {
  const seller = useSelector((state) => state.seller);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSellerOrdersData();
  }, []);

  const fetchSellerOrdersData = async () => {
    try {
      const resp = await dispatch(fetchSellerDashboard());

      if (!resp) {
        toast.error("Somewhere went wrong");
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="seller-main-cont">
      {seller.status === "loading" && <MainLoader />}
      <NavBar />
      <SideBar />
      <div className="dashboard-cont">
        <h3>Seller Dashboard</h3>

        <div className="dashboard-data-cont">
          <div className="dashboard-data">
            <h4>Total Revenue</h4>
            <p>{seller?.fetchOrder?.totalRevenue} Rs</p>
          </div>
          <div className="dashboard-data">
            <h4>Total Products</h4>
            <p>{seller?.fetchOrder?.totalProducts}</p>
          </div>
          <div className="dashboard-data">
            <h4>Total Orders</h4>
            <p>{seller?.fetchOrder?.totalOrders}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerDashboard;
