import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import HomeAbstract from "../../assests/svg/HomeAbstract";
import "./Home.scss";
import Fashion from "../../assests/svg/Fashion";
import Outdoor from "../../assests/svg/Outdoor";
import Books from "../../assests/svg/Books";
import Games from "../../assests/svg/Games";
import Other from "../../assests/svg/Other";
import Electronic from "../../assests/svg/Electronic";
import Music from "../../assests/svg/Music";
import MainLoader from "../Loading/MainLoader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  return (
    <div className="home">
      <NavBar />
      {user?.status === "loading" && <MainLoader />}
      <div className="home-hero1">
        <div>
          <h1>Welcome to RentSphere</h1>
          <p>Discover flexible rentals for everything from fashion to</p>
          <p>outdoor gear, books, games, and more!</p>
          <div className="hero1-btn-cont">
            <button
              className="explore-btn"
              onClick={() => navigate("/products")}
            >
              Explore Products
            </button>
            <button className="list-btn">List an Item</button>
          </div>
        </div>
        <HomeAbstract />
      </div>
      <div className="home-populor-category">
        <h2>Popular Categories</h2>
        <div className="categories">
          <div className="category">
            <Fashion />
            <h4>Fashion</h4>
          </div>
          <div className="category">
            <Outdoor />
            <h4>Outdoor Gear</h4>
          </div>
          <div className="category">
            <Electronic />
            <h4>Electronics</h4>
          </div>
          <div className="category">
            <Books />
            <h4>Books</h4>
          </div>
          <div className="category">
            <Music />
            <h4>Music Instruments</h4>
          </div>
          <div className="category">
            <Games />
            <h4>Games</h4>
          </div>
          <div className="category">
            <Other />
            <h4>And More</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
