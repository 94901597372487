import React, { useState } from "react";
import AppLogo from "../../assests/Images/logo.png";
import "./NavBar.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, logoutUser } from "../../slices/userSlice";

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [userCard, setUserCard] = useState(false);
  const logoutHandler = async () => {
    setUserCard(!userCard);
    await dispatch(logoutUser());
    await dispatch(fetchUser());
    navigate("/");
  };
  return (
    <div className="navbar">
      <div className="app-data">
        <img src={AppLogo} alt="Rent Sphere" />
        <h1>Rent Sphere</h1>
      </div>
      <div className="app-services">
        <ul>
          <li onClick={() => navigate("/")}>Home</li>
          <li>About</li>
          <li onClick={() => navigate("/products")}>Products</li>
          <li>Contact</li>
        </ul>
      </div>
      <div className="app-user">
        <input type="text" placeholder="search..." />
        {user && user?.user ? (
          <div className="nav-user">
            <img
              src={user?.user?.profilePicture}
              alt={user?.user?.name}
              onClick={() => setUserCard(!userCard)}
            />
            {userCard && (
              <div className="user-card">
                <ul>
                  <li onClick={() => navigate("/user")}>Profile</li>
                  <li>Settings</li>
                  <li className="li-not-bor" onClick={logoutHandler}>
                    Logout
                  </li>
                  {user?.user?.isSeller && 
                    <li className="li-not-bor" onClick={() => navigate('/seller/dashboard')}>
                      Seller Dashboard
                    </li>
                  }
                </ul>
              </div>
            )}
          </div>
        ) : (
          <button onClick={() => navigate("/auth")}>Register</button>
        )}
      </div>
    </div>
  );
};

export default NavBar;
