import React, { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductById } from "../../slices/productSlice";
import "./ProductById.scss";
import MainLoader from "../Loading/MainLoader";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
const ProductById = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  useEffect(() => {
    fetchProductByIdData();
  }, [dispatch, id]);

  const fetchProductByIdData = async () => {
    try {
      await dispatch(fetchProductById({ id }));
    } catch (error) {
      console.log(error);
    }
  };
  const formattedDate = new Date(
    product?.productById && product?.productById?.product?.updatedAt
  );
  return (
    <div className="producById-main">
      <NavBar />
      {product?.status === "loading" && <MainLoader />}
      <div className="product-data-cont">
        <div className="images-cont">
          <Carousel showThumbs={false} autoPlay={true} showStatus={false}>
            {product?.productById &&
              product?.productById?.product?.images?.map((item, index) => (
                <div key={index}>
                  <img
                    src={item}
                    alt="product-image"
                    className="product-image"
                  />
                </div>
              ))}
          </Carousel>
        </div>
        <div className="data-cont">
          <h2>
            {product?.productById && product?.productById?.product?.title}
          </h2>
          <h4>
            {product?.productById && product?.productById?.product?.description}
          </h4>
          <p className="product-category">
            {product?.productById && product?.productById?.product?.category}
          </p>
          <p
            className={
              product?.productById &&
              product?.productById?.product?.availability
                ? "product-avaliable"
                : "product-unavaliable"
            }
          >
            {product?.productById && product?.productById?.product?.availability
              ? "Avaliable"
              : "Unavaliable"}
          </p>
          <p>
            Price:
            <span className="other-data">
              {product?.productById &&
                product?.productById?.product?.pricePerDay}{" "}
              Rs/day
            </span>
          </p>

          <p>
            Last Updated:{" "}
            <span className="other-data">
              {formattedDate.toUTCString().replace(" GMT", "")}
            </span>{" "}
          </p>
          <p>
            Local Address:
            <span className="other-data">
              {" "}
              {product?.productById &&
                product?.productById?.product?.location.address}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductById;
