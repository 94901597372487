import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import NavBar from "../../components/NavBar/NavBar";
import "./SellerDashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import MainLoader from "../Loading/MainLoader";
import { fetchSellerOrders } from "../../slices/sellerSlice";
import toast from "react-hot-toast";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import Pagination from "../../components/Pagination/Pagination";
const SellerOrder = () => {
  const seller = useSelector((state) => state.seller);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1)
  useEffect(() => {
    fetchSellerOrdersData();
  }, [page]);

  const fetchSellerOrdersData = async () => {
    try {
      const resp = await dispatch(fetchSellerOrders({page}));

      if (!resp) {
        toast.error("Somewhere went wrong");
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="seller-main-cont">
      {seller.status === "loading" && <MainLoader />}
      <NavBar />
      <SideBar />
      <div className="seller-order-list-cont">
        {seller?.fetchOrder?.reciveOrders &&
          seller?.fetchOrder?.reciveOrders?.map((item, index) => (
            <div className="product-list-card" key={index}>
              <div>
                <img src={item?.productId?.images[0]} alt={item?.title} />
                <div className="product-list-card-data">
                  <h4>order_id: {item?._id}</h4>
                  <p>
                    <span>Product Name:</span>
                    {item?.productId?.title}
                  </p>
                  <p>
                    <span>Total Amount</span>
                    {item?.amount}Rs
                  </p>
                  <p>
                    <span>Order Date:</span>{" "}
                    {new Date(item?.orderDate).toLocaleString()}
                  </p>
                  <p>
                    <span>Duration:</span> {item?.duration} days
                  </p>
                  <p>
                    <span>Customer_id:</span> {item?.user}
                  </p>

                  <p>
                    <span>Customer Mobile:</span> {item?.mobileNo}
                  </p>
                  <p>
                    <span>Order Status:</span> {item?.order_status || "Pending"}
                  </p>
                </div>
              </div>

              <div className="product-list-card-btns">
                <MdEdit />
              </div>
            </div>
          ))}
        <Pagination page={page} setPage={setPage} totalPages={seller?.fetchOrder?.totalPages} pages={seller?.fetchOrder?.page} />
      </div>
    </div>
  );
};

export default SellerOrder;
