import React from "react";
import "./SellerDashboard.scss";
import { MdSpaceDashboard } from "react-icons/md";
import { FaCartArrowDown } from "react-icons/fa";
import { AiFillProduct } from "react-icons/ai";
import { RiFunctionAddFill } from "react-icons/ri";

import { useLocation, useNavigate } from "react-router-dom";

const SideBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <div className="seller-sidebar">
      <ul>
        <li
          className={pathname.split("/")[2] === "dashboard" ? "active":""}
          onClick={() => navigate("/seller/dashboard")}
        >
          <span>
            <MdSpaceDashboard />
          </span>
          <p>DashBoard</p>
        </li>
        <li
          className={pathname.split("/")[2] === "orders" ? "active":""}
          onClick={() => navigate("/seller/orders")}
        >
          <span>
            <FaCartArrowDown />
          </span>
          <p>Orders</p>
        </li>
        <li
          className={pathname.split("/")[2] === "products" ? "active":""}
          onClick={() => navigate("/seller/products")}
        >
          <span>
            <AiFillProduct />
          </span>
          <p>Products</p>
        </li>
        <li
          className={pathname.split("/")[2] === "addproduct" ? "active":""}
          onClick={() => navigate("/seller/addproduct")}
        >
          <span>
            <RiFunctionAddFill />
          </span>
          <p>Add Product</p>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
