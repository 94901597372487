import React from "react";

const FilterBar = ({
  category,
  setCategory,
  range,
  setRange,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
}) => {
  return (
    <div className="filter-main">
      <div>
        <label>Filter by Category:</label>
        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="">All</option>
          <option value="Fashion">Fashion</option>
          <option value="Outdoor">Outdoor</option>
          <option value="Books">Books</option>
          <option value="Games">Games</option>
          <option value="Other">Other</option>
          <option value="Electronics">Electronic</option>
          <option value="Music Instruments">Music</option>
        </select>
      </div>

      <div>
        <label>Filter by range:</label>
        <select value={range} onChange={(e) => setRange(e.target.value)}>
          <option value="50">50Km</option>
          <option value="40">40km</option>
          <option value="30">30km</option>
          <option value="20">20km</option>
          <option value="10">10km</option>
        </select>
      </div>
      <div>
        <label>Minimun Price:</label>
        <input
          type="text"
          value={minPrice}
          placeholder="minimun price"
          onChange={(e) => setMinPrice(e.target.value)}
        />
      </div>
      <div>
        <label>Maximum Price:</label>
        <input
          type="text"
          value={maxPrice}
          placeholder="maximum price"
          onChange={(e) => setMaxPrice(e.target.value)}
        />
      </div>
    </div>
  );
};

export default FilterBar;
