import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import { useSelector } from "react-redux";
import "./User.scss";
import MainLoader from "../Loading/MainLoader";

const User = () => {
  const user  = useSelector((state) => state.user);
  return (
    <div className="user-profile">
      {
        user?.status === 'loading' && <MainLoader />
      }
      <NavBar />
      <div className="user-data-cont">
        <div className="image-cont">
          <img src={user?.user?.user?.profilePicture} alt={user?.user?.user?.name} />
          <button>Edit</button>
        </div>
        <div className="data-cont">
          <p>
            Name: <span>{user?.user?.user?.name}</span>
          </p>
          <p>Email: <span>{user?.user?.user?.email}</span> </p>
          <p>Username: <span>{user?.user?.user?.username}</span> </p>
          <p>Location: <span>{user?.location?.lat}, {user?.location?.lon}</span> </p>
          <p>Verification: <span>{user?.user?.user?.isVerified ? "YES": "NO"}</span> </p>
        </div>
      </div>
    </div>
  );
};

export default User;
