import React from 'react'
import { useNavigate } from 'react-router-dom'

const ProductCard = ({item}) => {
  const navigate = useNavigate()
  return (
    <div className='product-card-main' onClick={() => navigate(`/product/${item?._id}`)}>
      <img src={item?.images[0]} alt="product" />
      <div>
        <p className='title'>{item?.title?.toString().slice(0, 20)}</p>
        <p>Price: <span>{item?.pricePerDay}rs/day</span> </p>
        <p>category: <span>{item?.category}</span> </p>
      </div>
    </div>
  )
}

export default ProductCard
