import React from 'react'

const Fashion = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    viewBox="0 0 500 500"
  >
    <g id="OBJECTS">
      <defs>
        <circle id="SVGID_1_" cx="248.193" cy="253.918" r="222.928"></circle>
      </defs>
      <clipPath id="SVGID_00000127016078387814526390000013900128318049284021_">
        <use xlinkHref="#SVGID_1_" overflow="visible"></use>
      </clipPath>
      <circle
        cx="248.193"
        cy="253.918"
        r="222.928"
        fill="#4964F4"
        clipPath="url(#SVGID_00000127016078387814526390000013900128318049284021_)"
        opacity="0.3"
      ></circle>
      <g
        fill="#4964F4"
        clipPath="url(#SVGID_00000127016078387814526390000013900128318049284021_)"
        opacity="0.5"
      >
        <path d="M398.468 223.947h51.067v22.209h-51.067zM453.524 223.947h51.067v22.209h-51.067zM425.647 250.207h51.067v22.209h-51.067zM479.058 199.539h51.067v22.209h-51.067z"></path>
      </g>
      <g
        fill="#4964F4"
        clipPath="url(#SVGID_00000127016078387814526390000013900128318049284021_)"
        opacity="0.5"
      >
        <path d="M368.814 409.053h-51.067v-22.209h51.067zM313.757 409.053H262.69v-22.209h51.067zM341.634 435.314h-51.067v-22.209h51.067zM288.223 384.646h-51.067v-22.21h51.067z"></path>
      </g>
      <g
        fill="#4964F4"
        clipPath="url(#SVGID_00000127016078387814526390000013900128318049284021_)"
        opacity="0.5"
      >
        <path d="M.241 316.792h51.067v22.209H.241zM55.298 316.792h51.067v22.209H55.298zM27.421 343.052h51.067v22.209H27.421zM80.832 292.384h51.067v22.209H80.832z"></path>
      </g>
      <g clipPath="url(#SVGID_00000127016078387814526390000013900128318049284021_)">
        <path
          fill="#FFF"
          d="M147.923 73.713 271.837 45.78l36.579 162.267-123.914 27.933z"
        ></path>
        <path
          fill="#4964F4"
          d="m244.169 124.5-16.931 3.817-16.931 3.817s-6.26 61.179-6.567 61.821 36.445-8.217 36.445-8.217 36.998-7.77 36.445-8.217c-.554-.447-32.461-53.021-32.461-53.021M235.215 70.074l-9.47 2.135-3.991 31.786-17.245-26.998-9.47 2.135-2.142 22.599 16.262 25.313 16.931-3.817 16.931-3.818 3.826-29.842z"
        ></path>
        <path
          fill="#4964F4"
          d="m188.54 208.29 106.162-23.932.585 2.598-106.162 23.931zM189.714 213.504l106.163-23.931.585 2.597L190.299 216.1zM190.89 218.72l106.162-23.932.586 2.597-106.163 23.931zM168.524 108.187l18.184-4.099 4.628 20.53-18.184 4.1z"
        ></path>
        <path
          fill="#613F75"
          d="m171.153 110.057 14.01-3.16 3.566 15.817-14.009 3.16zM254.3 109.323l24.543-5.535 6.25 27.71-24.544 5.536z"
        ></path>
        <path
          fill="#F7A9A8"
          d="m257.823 111.893 18.91-4.262 4.812 21.349-18.91 4.262z"
        ></path>
        <path
          fill="none"
          stroke="#613F75"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.958"
          d="M180.444 124.618c-.132 4.119-.136 8.448 1.811 12.08s6.509 6.203 10.281 4.542c1.618-.712 2.955-2.282 2.95-4.049s-1.71-3.469-3.439-3.101c-.715.153-1.333.608-1.833 1.142-2.601 2.783-2.1 7.298-.461 10.736 2.548 5.342 7.486 9.488 13.189 11.073a31 31 0 0 1-3.245-4.064 20.5 20.5 0 0 0 3.222 4.056l-6.555 1.083M242.999 75.657c4.762-1.053 9.844.963 13.35 4.353s5.618 7.984 6.988 12.665c1.369 4.68 2.068 9.528 3.206 14.27"
        ></path>
        <path
          fill="none"
          stroke="#613F75"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.958"
          d="M263.557 103.657a41 41 0 0 1 3.056 3.662 18.7 18.7 0 0 1 1.158-4.017"
        ></path>
        <path
          fill="#EB3059"
          d="m201.93 77.579 20.809 33.45 5.494-39.381-2.488.561-3.991 31.786-17.245-26.998zM244.169 124.5l-33.862 7.635-.393 3.832 36.254-8.174z"
        ></path>
        <path
          fill="#4964F4"
          d="m218.223 144.437-3.608 47.321 3.206-.737zM228.462 142.129l.707 46.348 2.186-.507zM234.906 140.676l19.251 42.166-2.193.481zM243.414 138.758l23.565 41.194-3.211.71z"
        ></path>
        <path
          fill="#F6E9EE"
          d="m147.928 73.706 36.586 162.269 123.916-27.939-129.903 36.514z"
        ></path>
      </g>
      <g clipPath="url(#SVGID_00000127016078387814526390000013900128318049284021_)">
        <path
          fill="#FFF"
          d="m81.828 129.73 94.061 22.599-27.231 113.344-94.061-22.599z"
        ></path>
        <path
          fill="#4964F4"
          d="m87.1 140.086 16.71 4.014-4.015 16.709-16.708-4.014z"
        ></path>
        <path
          fill="#F7A9A8"
          d="m107.326 144.944 16.71 4.015-4.015 16.709-16.71-4.015z"
        ></path>
        <path
          fill="#7D82B8"
          d="m127.552 149.804 16.709 4.014-4.014 16.71-16.71-4.015z"
        ></path>
        <path
          fill="#613F75"
          d="m147.778 154.663 16.709 4.015-4.014 16.709-16.71-4.015z"
        ></path>
        <path
          fill="#F7A9A8"
          d="m82.189 160.531 16.709 4.014-4.015 16.71-16.709-4.015zM102.415 165.39l16.709 4.014-4.015 16.71-16.709-4.015z"
        ></path>
        <path
          fill="#7D82B8"
          d="m122.64 170.25 16.708 4.014-4.014 16.71-16.709-4.015z"
        ></path>
        <path
          fill="#613F75"
          d="m142.865 175.11 16.71 4.014-4.015 16.709-16.709-4.015z"
        ></path>
        <path
          fill="#7D82B8"
          d="m77.276 180.977 16.709 4.014-4.014 16.71-16.71-4.015z"
        ></path>
        <path
          fill="#F7A9A8"
          d="m97.502 185.836 16.709 4.015-4.014 16.709-16.71-4.015z"
        ></path>
        <path
          fill="#7D82B8"
          d="m117.73 190.696 16.708 4.014-4.014 16.709-16.71-4.014z"
        ></path>
        <path
          fill="#613F75"
          d="m137.954 195.555 16.71 4.014-4.015 16.709-16.71-4.015zM72.364 201.423l16.709 4.014-4.015 16.71-16.709-4.015z"
        ></path>
        <path
          fill="#F7A9A8"
          d="m92.59 206.282 16.708 4.015-4.014 16.709-16.709-4.015z"
        ></path>
        <path
          fill="#7D82B8"
          d="m112.817 211.14 16.709 4.015-4.014 16.709-16.71-4.015z"
        ></path>
        <path
          fill="#613F75"
          d="m133.042 216 16.709 4.015-4.015 16.709-16.709-4.015z"
        ></path>
        <path
          fill="#4964F4"
          d="m67.452 221.869 16.709 4.014-4.014 16.71-16.71-4.015z"
        ></path>
        <path
          fill="#F7A9A8"
          d="m87.678 226.727 16.709 4.015-4.014 16.709-16.71-4.015z"
        ></path>
        <path
          fill="#7D82B8"
          d="m107.904 231.587 16.709 4.014-4.014 16.71-16.71-4.015z"
        ></path>
        <path
          fill="#613F75"
          d="m128.131 236.446 16.71 4.014-4.015 16.71-16.709-4.015z"
        ></path>
      </g>
      <g clipPath="url(#SVGID_00000127016078387814526390000013900128318049284021_)">
        <path
          fill="#C6556C"
          d="m248.193 351.954 132.016 27.129-12.819-64.095-61.463-16.66z"
        ></path>
        <path
          fill="#613F75"
          d="M337.814 354.382h10.244v108.235h-10.244z"
        ></path>
        <path
          fill="#4964F4"
          d="M293.473 161.281c1.195-4.259 1.38-8.747 1.083-13.16-.498-7.398-2.369-14.777-6.082-21.195-3.36-5.807-8.292-10.785-14.274-13.786-.265.014-.422.02-.422.02v71.165l.138.242c2.39-1.535 4.7-3.184 6.881-5.008 5.773-4.832 10.642-11.03 12.676-18.278M336.221 133.41c8.306 14.085 12.671 30.133 15.854 46.172 8.032-24.45 11.1-51.317 9.64-77.047a20 20 0 0 1-2.333-1.408c-5.844-4.125-5.844-19.252-5.844-19.252h-37.473s-.344 15.814-8.595 23.034c-.167.146-.353.286-.534.428 12.275 5.884 22.318 16.259 29.285 28.073"
        ></path>
        <path
          fill="#4964F4"
          d="M342.785 233.321c10.256.348 20.608.167 30.643-1.975 4.863-1.038 9.66-2.566 14.179-4.648l6.841-44.781s12.376-16.502 11.345-24.409-32.66-51.912-32.66-51.912-2.55-.117-5.819-.966c1.904 31.1-4.138 61.082-17.788 89.311-2.417-17.883-6.063-35.856-14.105-52.01-7.099-14.259-17.979-27.078-31.948-34.52-9.538 4.538-26.443 5.583-29.273 5.729 5.981 3.001 10.914 7.979 14.274 13.786 3.713 6.418 5.585 13.797 6.082 21.195.297 4.413.112 8.901-1.083 13.16-2.034 7.247-6.903 13.446-12.676 18.276-2.181 1.825-4.491 3.473-6.881 5.008l20.177 35.416c6.312 4.803 13.788 8.021 21.489 10.008 8.863 2.289 18.054 3.022 27.203 3.332"
        ></path>
        <path
          fill="#EB3059"
          d="M335.42 141.932c8.043 16.154 11.688 34.127 14.105 52.01 13.65-28.229 19.692-58.211 17.788-89.311-1.753-.456-3.71-1.119-5.599-2.095 1.46 25.73-1.608 52.597-9.64 77.047-3.183-16.039-7.548-32.087-15.854-46.172-6.967-11.814-17.009-22.189-29.285-28.074-.977.763-2.152 1.451-3.463 2.075 13.969 7.442 24.849 20.261 31.948 34.52M373.429 231.346c-10.036 2.142-20.388 2.322-30.643 1.975-9.149-.31-18.34-1.043-27.204-3.331-7.701-1.987-15.177-5.206-21.489-10.008l5.934 10.416c11.048 7.404 24.677 10.825 38.05 12.083 11.023 1.037 22.261.77 32.997-1.937 5.312-1.339 10.501-3.303 15.321-5.909l1.212-7.937c-4.518 2.083-9.315 3.611-14.178 4.648"
        ></path>
        <path
          fill="#4964F4"
          d="M338.077 242.481c-13.374-1.258-27.002-4.679-38.05-12.083l2.118 3.717 47.954 22.932 36.178-21.642.118-.77c-4.82 2.605-10.009 4.569-15.321 5.909-10.736 2.708-21.974 2.974-32.997 1.937"
        ></path>
        <path
          fill="#4964F4"
          d="M302.145 234.115c2.117 1.02 22.155 10.57 44.523 9.756s39.61-8.466 39.61-8.466l38.212 143.256s-15.854 9.275-44.28.421-47.055-27.915-47.055-27.915l-13.747-14.74-12.48-2.08s-10.864 6.621-31.556 10.762-33.404 4.66-33.404 4.66z"
        ></path>
        <path
          fill="#4964F4"
          d="m380.209 256.414 32.626 125.804-5.383.576zM380.209 379.083l-10.971-117.772 3.921 115.298zM348.058 263.336l-14.904 87.832 6.731 5.962zM327.678 257.064c-.335 1.975-27.651 80.645-27.651 80.645l6.899-3.361zM313.852 248.677l-46.873 97.998 8.391-1.565z"
        ></path>
      </g>
      <path
        fill="#D2AAD1"
        d="M353.537 81.875s-3.894 9.452-17.557 9.364-19.916-9.364-19.916-9.364z"
        clipPath="url(#SVGID_00000127016078387814526390000013900128318049284021_)"
      ></path>
      <g clipPath="url(#SVGID_00000127016078387814526390000013900128318049284021_)">
        <path
          fill="#F7A9A8"
          d="m167.9 307.17 56.087 34.127 43.92-70.628s-6.529-19.586 0-29.379 20.476-16.915 22.257-16.322c1.78.593 1.484 7.122 0 9.793s1.227 4.451 1.227 4.451 14.472-9.223 16.912-6.935c2.441 2.288 2.441 9.61.915 10.831-1.525 1.22-3.661 3.051-3.661 3.051s5.949 3.051 5.797 9.305-.763 16.322-4.576 17.847-17.39 1.068-17.39 1.068l-45.128 97.342-53.759-2.209z"
        ></path>
        <path
          fill="#613F75"
          d="M289.785 235.406c-.434 1.244-1.192 2.376-2.134 3.332a9.2 9.2 0 0 1-1.591 1.244c-.293.182-.581.333-.641.483-.069.115-.006.347.128.648 1.031 2.482 1.376 5.246 1.037 7.921-.339 2.676-1.381 5.272-3.032 7.39-1.622 2.134-3.797 3.831-6.239 4.812 1.122-.682 2.197-1.425 3.126-2.329a15.9 15.9 0 0 0 2.516-2.914c1.436-2.121 2.377-4.55 2.679-7.079.341-2.523.046-5.118-.773-7.539-.092-.288-.297-.758-.022-1.242.289-.429.666-.513.919-.672a9 9 0 0 0 1.579-1.068c1.005-.802 1.818-1.836 2.448-2.987M291.456 239.065c-1.585 1.366-2.221 3.587-1.645 5.426.294.919.83 1.762 1.563 2.37.716.624 1.623.983 2.591 1.157 1.953.353 4.014.189 6.007-.234 2.001-.431 3.975-1.087 5.954-1.753-1.828 1.005-3.732 1.907-5.775 2.479-2.034.587-4.21.82-6.366.449-1.068-.208-2.17-.642-3.009-1.419-.832-.765-1.436-1.766-1.685-2.856-.546-2.204.551-4.567 2.365-5.619"
        ></path>
        <path
          fill="#613F75"
          d="M292.059 247.877a7.65 7.65 0 0 0-3.189 1.95 9.8 9.8 0 0 0-2.103 3.037c-1 2.269-1.288 4.791-1.339 7.304-.06 2.528-.005 5.062.536 7.502a13.4 13.4 0 0 0 1.29 3.506 10.7 10.7 0 0 0 2.288 2.999c-1.078-.7-1.964-1.683-2.692-2.76a13.8 13.8 0 0 1-1.599-3.56c-.739-2.519-.819-5.147-.78-7.711.076-2.566.401-5.245 1.637-7.603.622-1.164 1.443-2.221 2.45-3.063 1.021-.814 2.224-1.431 3.501-1.601"
        ></path>
        <path
          fill="#613F75"
          d="M285.085 257.048c2.081-.805 4.205-1.479 6.343-2.105 2.135-.637 4.299-1.169 6.47-1.669 2.176-.477 4.359-.921 6.562-1.258 2.201-.347 4.409-.646 6.635-.806-2.162.553-4.331 1.033-6.495 1.53l-6.492 1.468-6.497 1.445c-2.169.476-4.335.969-6.526 1.395M285.066 265.571l6.402-.393c2.127-.155 4.252-.295 6.372-.492 2.122-.175 4.239-.407 6.36-.625l6.373-.725c-2.057.617-4.156 1.072-6.268 1.454-2.111.391-4.244.651-6.382.849-2.139.175-4.285.291-6.432.272-2.146-.008-4.292-.091-6.425-.34M293.137 236.904a2.789 2.789 0 1 1-5.577 0 2.789 2.789 0 0 1 5.577 0"
        ></path>
        <path
          fill="#5C3B75"
          d="M313.968 220.079a1323 1323 0 0 1-11.601 8.705c-1.936 1.447-3.84 2.94-5.842 4.294-2.017 1.334-4.023 2.683-6.149 3.865l-.056-.078c1.806-1.629 3.732-3.09 5.647-4.567 1.93-1.456 3.958-2.775 5.954-4.138a1309 1309 0 0 1 11.991-8.159z"
        ></path>
        <path
          fill="#F7A9A8"
          d="M124.971 285.131c10.793 8.635 24.483 13.935 38.282 14.755-3.246-8.066-4.704-16.874-4.109-25.549 5.345-.074 10.688.688 16.026.396s10.892-1.807 14.604-5.654c2.329-2.414 3.746-5.541 5.119-8.601.883-1.969 1.59-3.921 2.474-5.89-4.891-4.136-8.182-9.9-9.495-16.169 2.642 4.242 6.779 7.586 11.269 9.782l.647-10.586 9.296-4.43-11.161-24.553s3.247-9.334-2.435-23.742c-5.682-14.407-54.788-9.334-54.788-9.334-8.695 3.279-15.324 11.187-18.583 19.889s-3.663 18.224-3.053 27.497a133 133 0 0 0 2.95 20.424c1.124 4.998 2.54 9.942 3.235 15.018 1.222 8.93.764 17.792-.278 26.747"
        ></path>
        <path
          fill="#613F75"
          d="M188.85 216.221c-.358 2.373-1.643 4.147-2.871 3.962s-1.932-2.259-1.574-4.632 1.643-4.147 2.871-3.962 1.932 2.259 1.574 4.632"
        ></path>
        <path
          fill="#604075"
          d="M177.887 209.604c.133-1.41.607-2.797 1.284-4.1.7-1.292 1.66-2.473 2.84-3.437 1.159-.957 2.467-1.965 4.035-2.15 1.53-.2 3.153-.003 4.47.931l-.029.286c-1.423.655-2.588 1.078-3.723 1.566-1.098.502-2.338.645-3.443 1.257a11.9 11.9 0 0 0-2.973 2.371c-.902.952-1.617 2.096-2.183 3.35z"
        ></path>
        <path
          fill="#513570"
          d="M139.394 216.365c.557-1.559 2.248-2.551 3.922-2.642 1.722-.121 3.414.749 4.543 2.007 1.159 1.258 1.847 2.897 2.116 4.547.296 1.656.207 3.324.051 4.943l-.025.26-.229.108c-.882.415-1.733.902-2.538 1.456-.84.54-1.556 1.104-1.599 2.027-.073.896.338 1.819.995 2.514.658.715 1.536 1.224 2.382 1.756-.921-.388-1.847-.811-2.637-1.491-.802-.659-1.363-1.691-1.352-2.809.004-.555.2-1.143.562-1.587.356-.446.779-.762 1.178-1.07a19 19 0 0 1 2.606-1.617l-.254.368c.145-1.585.169-3.17-.071-4.7-.245-1.525-.825-2.986-1.811-4.154-.966-1.154-2.359-2.034-3.904-2.024-1.512-.024-3.149.661-3.935 2.108"
        ></path>
        <path
          fill="#F7A9A8"
          d="M189.722 232.479c0 1.967-2.506 3.562-5.598 3.562s-5.598-1.595-5.598-3.562 2.506-3.562 5.598-3.562 5.598 1.595 5.598 3.562"
          opacity="0.5"
        ></path>
        <path
          fill="#7678B1"
          d="M117.48 349.068c-3.006-14.685-4.307-29.842-3.181-44.76-6.737 15.5-14.072 35.114-14.278 47.437-.382 22.911 15.93 86.297 15.93 86.297l39.67 32.892 18.492 3.247c-13.816-18.301-24.471-38.92-33.959-59.851-9.528-21.02-18.045-42.652-22.674-65.262"
        ></path>
        <path
          fill="#7D82B8"
          d="m198.248 406.277 5.268-23.708s9.819-27.779 6.945-37.119-22.99-20.355-34.484-31.611-16.186-26.301-16.186-26.301-6.913 1.152-15.499-.595c-8.586-1.748-18.859-6.232-18.859-6.232s-5.346 10.28-11.134 23.598c-1.126 14.918.175 30.074 3.181 44.76 4.629 22.61 13.146 44.242 22.674 65.262 9.488 20.931 20.144 41.55 33.959 59.851l44.251 7.769z"
        ></path>
        <path
          fill="#F7A9A8"
          d="M134.578 312.359c12.209-12.614 44.276 16.046 56.756 33.875s41.601 54.973 41.601 54.973l64.779-46.95s-.297-8.617 2.377-13.075 6.835-6.835 6.835-6.835l12.48 2.08c.299.263.193-.135.421-.462 5.702-8.207 7.409-18.895 14.073-26.341.673-.752 1.659-1.525 2.589-1.136.831.348 1.057 1.411 1.072 2.312.039 2.331-.564 4.621-1.219 6.858a134.5 134.5 0 0 1-9.491 23.737c4.951-6.287 9.613-13.115 13.287-20.224 1.527-2.954 2.989-6.041 5.417-8.313.4-.374.92-.747 1.448-.603.327.089.57.36.759.641 1.243 1.851.826 4.318.263 6.476a74.8 74.8 0 0 1-12.786 26.317c5.975-6.396 11.069-12.924 15.314-20.578 1.176-2.121 2.354-4.367 4.351-5.743.663-.457 1.606-.786 2.227-.275.388.319.503.86.55 1.361.258 2.721-.731 5.396-1.82 7.904a88 88 0 0 1-10.832 18.422c-.842 1.099-1.822 2.25-3.179 2.526 6.442-1.364 9.251-9.101 15.519-11.123.747 1.273.247 2.89-.336 4.246-3.707 8.631-10.242 16.021-18.356 20.754s-17.763 6.786-27.101 5.765c0 0-20.735 26.643-34.693 39.886-13.958 13.242-44.379 33.464-44.379 33.464s-60.079-47.802-79.275-69.79c-19.685-22.55-38.376-39.769-18.651-60.149"
        ></path>
        <path
          fill="#613F75"
          d="M220.654 413.994c1.676-2.792 3.759-5.315 6.026-7.643 2.287-2.308 4.773-4.435 7.538-6.157-2.288 2.312-4.61 4.525-6.855 6.828-2.266 2.283-4.439 4.643-6.709 6.972"
        ></path>
        <path
          fill="#F7A9A8"
          d="M149.011 233.34c-1.061-.549-2.154-1.124-2.906-2.052s-1.077-2.313-.449-3.329c.257-.414.643-.73 1.034-1.021a18.4 18.4 0 0 1 2.88-1.761c.203-2.145.232-4.357-.45-6.401s-2.18-3.912-4.231-4.57c-2.052-.659-4.614.193-5.495 2.159 0 0-2.059 5.831 1.353 12.553 3.412 6.721 8.264 4.422 8.264 4.422M147.724 265.416a71.5 71.5 0 0 0 11.306 12.909 56 56 0 0 1 .113-3.988c5.345-.074 10.688.688 16.026.396s10.892-1.807 14.604-5.654c.372-.386.715-.796 1.044-1.215-3.495 2.529-8.18 3.239-12.578 3.385a70.8 70.8 0 0 1-30.515-5.833"
          opacity="0.5"
        ></path>
        <path
          fill="#523669"
          d="M142.478 255.136c-3.081-1.103-5.875-2.868-8.64-4.619-.789-.5-1.602-1.022-2.121-1.798-.469-.701-.656-1.547-.829-2.373a170 170 0 0 1-1.774-9.982 56.2 56.2 0 0 0-5.692 11.157l2.032-41.119-14.114 37.358c-1.077 2.85-2.46 6.016-5.32 7.067-.288-5.445.501-10.886 1.388-16.266a437 437 0 0 1 12.077-52.679c-10.176 21.117-21.882 41.454-33.576 61.77 6.209-16.8 9.234-34.593 14.416-51.737 3.744-12.384 8.604-24.395 13.756-36.269-1.948 1.351-3.447 2.828-4.867 4.331-4.642 4.915-43.145 96.667-43.145 96.667l58.217 18.049 3.391-15.426 1.204 16.851 2.444.758 1.241-11.517 1.522 12.374 21.82 6.765L154.26 256c-3.912.757-8.028.48-11.782-.864"
        ></path>
        <path
          fill="#613F75"
          d="M200.645 184.767a34.64 34.64 0 0 1 1.87 14.846 20.4 20.4 0 0 0 3.465-9.441c.302-2.972-.059-5.987-.824-8.875-1.867-7.043-6.207-13.404-12.085-17.71a31.8 31.8 0 0 0-5.593-3.267c-5.561-.079-10.268.203-10.268.203s-19.115-11.742-38.503-10.923c-13.458.568-20.203 2.98-24.624 6.045-5.152 11.874-10.013 23.884-13.756 36.269-5.182 17.144-8.207 34.937-14.416 51.737 11.694-20.315 23.4-40.653 33.576-61.77a437 437 0 0 0-12.077 52.679c-.887 5.38-1.676 10.82-1.388 16.266 2.86-1.05 4.243-4.216 5.32-7.067l14.114-37.358-2.032 41.119a56.2 56.2 0 0 1 5.692-11.157 169 169 0 0 0 1.774 9.982c.172.825.36 1.672.829 2.373.519.776 1.332 1.299 2.121 1.798 2.765 1.751 5.559 3.516 8.64 4.619 3.755 1.345 7.87 1.622 11.784.863l-1.082-18.712s-13.668 3.411-17.113-9.365 4.163-21.534 11.628-18.663 6.604 14.643 6.604 14.643 9.906-5.455 12.92-17.371c3.015-11.915 2.618-25.528 2.618-25.528s24.883-1.121 26.761 6.969c.761 3.278 1.123 7.291 1.284 10.905 2.666-4.118 3.721-9.286 2.761-14.109"
        ></path>
        <path
          fill="#523669"
          d="M204.517 163.254c-2.527-2.23-10.393-2.84-17.039-2.934a31.8 31.8 0 0 1 5.593 3.267c5.878 4.306 10.218 10.667 12.085 17.71.765 2.887 1.126 5.903.824 8.875a20.4 20.4 0 0 1-3.465 9.441 34.64 34.64 0 0 0-1.87-14.846c.959 4.823-.095 9.991-2.764 14.111.237 5.306.041 9.753.041 9.753s15.059-10.697 16.151-18.889-4.914-22.392-9.556-26.488"
        ></path>
      </g>
      <path
        fill="#FFF"
        d="M157.298 236.954a3.905 3.905 0 1 1-7.81 0 3.905 3.905 0 0 1 7.81 0"
        clipPath="url(#SVGID_00000127016078387814526390000013900128318049284021_)"
      ></path>
      <path
        fill="#FFF"
        d="M153.393 240.859a9.11 9.11 0 1 0 0 18.22 9.11 9.11 0 0 0 0-18.22m0 16.205a7.095 7.095 0 1 1 0-14.19 7.095 7.095 0 0 1 0 14.19"
        clipPath="url(#SVGID_00000127016078387814526390000013900128318049284021_)"
      ></path>
      <ellipse
        cx="237.156"
        cy="244.77"
        fill="none"
        stroke="#4964F4"
        strokeDasharray="2.8001,2.8001"
        strokeMiterlimit="10"
        strokeWidth="0.934"
        rx="219.74"
        ry="215.19"
        transform="rotate(-83.604 237.157 244.764)"
      ></ellipse>
      <ellipse
        cx="258.642"
        cy="260.254"
        fill="none"
        stroke="#613F75"
        strokeDasharray="2.8545,2.8545"
        strokeMiterlimit="10"
        strokeWidth="0.953"
        opacity="0.4"
        rx="218.674"
        ry="224.694"
        transform="rotate(-83.604 258.644 260.248)"
      ></ellipse>
    </g>
  </svg>
  )
}

export default Fashion
