import React, { useRef, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import SideBar from "./SideBar";
import { MdDeleteForever } from "react-icons/md";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { sellerAddProduct } from "../../slices/sellerSlice";
import MainLoader from "../Loading/MainLoader";
import { userSlice } from "../../slices/userSlice";

const SellerAddProduct = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [avaliable, setAvaliable] = useState("");
  const [desc, setDesc] = useState("");
  const [address, setAddress] = useState("");
  const [category, setCategory] = useState("");
  const [condition, setCondition] = useState("");
  const [images, setImages] = useState([]);
  const [prevImages, setPrevImages] = useState([]);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const seller = useSelector((state) => state.seller);
  const { location } = useSelector((state) => state.user);

  const createProductHandler = async () => {
    try {
      if (
        !name ||
        !price ||
        !avaliable ||
        !desc ||
        !address ||
        !category ||
        !condition ||
        !images
      ) {
        toast.error("Please provide all details");
        return;
      }

      if (!location?.lat || !location.lon) {
        toast.error("Please provide your location");
        navigator.geolocation.getCurrentPosition(
          (data) => {
            dispatch(
              userSlice.actions.setUserLocation({
                lat: data.coords.latitude,
                lon: data.coords.longitude,
              })
            );
          },
          (error) => toast.error(error?.message, { duration: 2000 })
        );
        return;
      }

      const { payload } = await dispatch(
        sellerAddProduct({
          name,
          price,
          avaliable,
          desc,
          address,
          category,
          condition,
          images,
        })
      );

      if (payload) {
        setName("");
        setPrice("");
        setAddress("");
        setAvaliable("");
        setDesc("");
        setCategory("");
        setCondition("");
        setImages([]);
        setPrevImages([])
        fileInputRef.current.value = null;
        return;
      }

      toast.error("Retry again and check product details");
    } catch (error) {
      console.log(error);
    }
  };

  const imageFileHandler = async (e) => {
    try {
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i];
        file = URL.createObjectURL(file);
        setPrevImages((prev) => [...prev, file]);
      }
      const selectedFiles = Array.from(e.target.files);
      setImages(selectedFiles);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteImageHandler = (async) => {
    try {
      setPrevImages((prev) => {
        if (prev.length === 0 || !prev) {
          return prev;
        }
        if (prev.length === 1) {
          fileInputRef.current.value = null;
        }
        return prev.slice(0, -1);
      });

      setImages((prev) => {
        if (prev.length === 0 ||!prev) {
          return prev;
        }
        if (prev.length === 1) {
          fileInputRef.current.value = null;
        }
        return prev.slice(0, -1);
      })
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="seller-main-cont">
      {seller?.status === "loading" && <MainLoader />}
      <NavBar />
      <SideBar />
      <div className="addproduct-cont">
        <h3>Seller Add Product</h3>

        <div className="addproduct-data">
          <div className="addproduct-inputs">
            <input
              type="text"
              placeholder="Product Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Product Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <select
              value={avaliable}
              onChange={(e) => setAvaliable(e.target.value)}
            >
              <option value="">Choose Avalibility</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
            <textarea
              placeholder="Product description"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
            <input
              type="text"
              placeholder="Product Location Addresss"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <input
              type="file"
              multiple
              ref={fileInputRef}
              onChange={(e) => imageFileHandler(e)}
              accept=".jpg, .jpeg, .png, .webp"
            />
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select Product Category</option>
              <option value="Tools">Tools</option>
              <option value="Electronics">Electronics</option>
              <option value="Outdoor">Outdoor</option>
              <option value="Vehicles">Vehicles</option>
              <option value="Fashion">Fashion</option>
              <option value="Home Appliances">Home Appliances</option>
              <option value="Sports Equipment">Sports Equipment</option>
              <option value="Furniture">Furniture</option>
              <option value="Books">Books</option>
              <option value="Toys & Games">Toys & Games</option>
              <option value="Art & Collectibles">Art & Collectibles</option>
              <option value="Music Instruments">Music Instruments</option>
              <option value="Kitchenware">Kitchenware</option>
              <option value="Party Supplies">Party Supplies</option>
              <option value="Luggage & Bags">Books</option>
              <option value="Luggage & Bags">Luggage & Bags</option>
              <option value="Pets & Animals">Pets & Animals</option>
              <option value="Others">Others</option>
            </select>
            <input
              type="text"
              placeholder="Product terms and conditions"
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
            />
            <button onClick={createProductHandler}>Create Product</button>
          </div>
          <div className="addproduct-preview">
            <div className="product-card">
              <img src={prevImages[0]} alt="images" />
              <div>
                {name && <h4>{name}</h4>}
                {price && (
                  <p>
                    Price: <span>{price}Rs/day</span>
                  </p>
                )}
                {category && (
                  <p>
                    Category: <span>{category}</span>
                  </p>
                )}
                {avaliable && (
                  <p>
                    avaliable:{" "}
                    <span>{avaliable === "true" ? "Yes" : "No"}</span>
                  </p>
                )}
                {address && (
                  <p>
                    Address: <span>{address}</span>
                  </p>
                )}
                {condition && (
                  <p>
                    Condition: <span>{condition}</span>
                  </p>
                )}
              </div>
            </div>
            <div className="product-images-preview">
              {
                console.log(prevImages)
              }
              {prevImages.length > 0 &&
                prevImages.map((item, index) => (
                  <img key={index} src={prevImages[index]} alt="images" />
                ))}
              {prevImages.length > 0 && (
                <MdDeleteForever onClick={deleteImageHandler} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerAddProduct;
