import React, { useState } from "react";
import "./Auth.scss";
import AppLogo from "../../assests/Images/logo.png";
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUser,
  googleLoginUser,
  loginUser,
  registerUser,
  verifyOtp,
} from "../../slices/userSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import MainLoader from "../Loading/MainLoader";

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [authMethod, setAuthMethod] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerName, setRegisterName] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [otp, setOtp] = useState("");
  
  const responseGoogle = async (response) => {
    try {
      const { payload } = await dispatch(
        googleLoginUser({ code: response?.code })
      );
      if (payload?.success) {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const gogleHanl = useGoogleLogin({
    onSuccess: responseGoogle,
    onFailure: responseGoogle,
    flow: "auth-code",
  });

  const signupHandler = async () => {
    try {
      if (!registerEmail || !registerName || !registerPassword) {
        toast.error("Please enter all fields", { duration: 2000 });
      }

      const resp = await dispatch(
        registerUser({
          email: registerEmail,
          password: registerPassword,
          name: registerName,
        })
      );

      if (resp.payload) {
        setAuthMethod("otpVerification");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const otpVerifyHandler = async () => {
    try {
      const resp = await dispatch(verifyOtp({ otp: otp }));
      if (resp?.payload) {
        setAuthMethod("login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loginHandler = async () => {
    try {
      const resp = await dispatch(loginUser({ email, password }));

      if (resp?.payload) {
        dispatch(fetchUser());
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="auth-main">
      {user?.status === "loading" && <MainLoader />}
      <div className="auth-cont">
        <div className="auth-img-cont">
          <img src={AppLogo} alt="Rent Sphere" />
        </div>
        <div className="auth-login-cont">
          <h1>Rent Sphere</h1>
          {authMethod === "login" && (
            <>
              <p>Login</p>
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <p className="forget-pass">Forget Password?</p>
              <button onClick={loginHandler}>Login</button>
              <button className="google-btn" onClick={gogleHanl}>
                <FcGoogle />
                Google
              </button>
              <p className="signup-navi">
                Don't have any accoutn?{" "}
                <span onClick={() => setAuthMethod("signup")}>
                  create an account
                </span>
              </p>
              <p className="signup-navi">
                Already Signuped?{" "}
                <span onClick={() => setAuthMethod("otpVerification")}>
                  verify account
                </span>
              </p>
            </>
          )}
          {authMethod === "signup" && (
            <>
              <p>Sign-up</p>
              <input
                type="text"
                placeholder="Name"
                value={registerName}
                onChange={(e) => setRegisterName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email"
                value={registerEmail}
                onChange={(e) => setRegisterEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                value={registerPassword}
                onChange={(e) => setRegisterPassword(e.target.value)}
              />

              <button onClick={signupHandler}>Register</button>
              <button className="google-btn" onClick={gogleHanl}>
                <FcGoogle />
                Google
              </button>
              <p className="signup-navi">
                Already have an account?{" "}
                <span onClick={() => setAuthMethod("login")}>
                  login account
                </span>
              </p>
              <p className="signup-navi">
                Already Signuped?{" "}
                <span onClick={() => setAuthMethod("otpVerification")}>
                  verify account
                </span>
              </p>
            </>
          )}
          {authMethod === "otpVerification" && (
            <>
              <p>OTP - Verification</p>
              <input
                type="num"
                placeholder="OTP"
                maxLength={4}
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <button onClick={otpVerifyHandler}>Verify</button>
              <p className="signup-navi">
                <span onClick={() => setAuthMethod("login")}>
                  login account
                </span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Auth;
