import React from 'react'

const Games = () => {
  return (
<svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    viewBox="0 0 3710 3710"
  >
    <path id="Background" fill="#FFF" d="M0 0h3710v3710H0z"></path>
    <g id="Illustration">
      <linearGradient
        id="SVGID_1_"
        x1="3514.733"
        x2="1542.574"
        y1="3703.399"
        y2="1604.004"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#DAE3FE"></stop>
        <stop offset="1" stopColor="#E9EFFD"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M2922.594 2861.13c-72.804 75.044-137.947 119.157-187.822 152.46-486.425 324.791-1042.461 231.178-1214.374 199.181-277.36-51.623-511.49-88.04-712.65-269.78-457.096-412.968-434.863-1274.45-85.586-1808.1 41.414-63.276 390.162-580.228 947.402-653.071 13.629-1.782 23.799-2.796 32.705-3.739 86.204-9.127 620.392-57.298 1055.969 307.629 62.351 52.237 162.691 146.46 255.739 290.165 340.225 525.452 341.303 1339.26-91.383 1785.255"
      ></path>
      <linearGradient
        id="SVGID_00000068659638367708897830000009589073417585109426_"
        x1="3149.615"
        x2="3021.51"
        y1="70.103"
        y2="272.374"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000068659638367708897830000009589073417585109426_)"
        d="m3124.752 1012.236-1804.127-87.514c-92.279-4.475-165.532 68.085-162.785 161.894l29.786 1014.591c2.663 90.93 78.878 161.565 169.215 158.019l1769.993-69.524c69.718-2.738 125.143-68.669 125.143-147.448v-877.147c.001-80.931-56.337-149.433-127.225-152.871"
      ></path>
      <path
        fill="#7D97F4"
        d="m3048.633 1072.772-1646.955-77.749c-84.251-3.976-151.017 60.487-148.508 143.828l27.253 901.373c2.431 80.783 72.046 143.537 154.525 140.386l1615.573-61.765c63.653-2.432 113.806-61.007 113.806-130.995v-779.267c0-71.898-50.972-132.756-115.694-135.811"
      ></path>
      <g fill="#FFF" opacity="0.6">
        <path d="M1421.207 1788.46c0 13.83-11.211 25.042-25.042 25.042s-25.042-11.211-25.042-25.042 11.212-25.042 25.042-25.042 25.042 11.211 25.042 25.042M2551.412 1863.955c0 13.83-11.212 25.042-25.042 25.042s-25.041-11.212-25.041-25.042 11.211-25.041 25.041-25.041 25.042 11.211 25.042 25.041M1685.955 1563.987c0 12.025-9.748 21.773-21.772 21.773-12.025 0-21.773-9.748-21.773-21.773 0-12.024 9.748-21.772 21.773-21.772 12.024 0 21.772 9.748 21.772 21.772M2028.831 1402.566c0 8.743-7.088 15.831-15.831 15.831s-15.831-7.088-15.831-15.831 7.087-15.831 15.831-15.831 15.831 7.088 15.831 15.831M2716.003 1419.261c0 8.743-7.088 15.831-15.831 15.831s-15.831-7.088-15.831-15.831 7.087-15.831 15.831-15.831 15.831 7.087 15.831 15.831M3087.719 1936.648c0 8.743-7.088 15.831-15.831 15.831s-15.831-7.088-15.831-15.831 7.088-15.831 15.831-15.831 15.831 7.088 15.831 15.831M2338.546 1156.52c0 7.026-5.696 12.722-12.722 12.722-7.027 0-12.722-5.696-12.722-12.722s5.696-12.722 12.722-12.722c7.027 0 12.722 5.696 12.722 12.722M2009.25 1823.092c0 7.601-6.163 13.764-13.765 13.764s-13.764-6.163-13.764-13.764c0-7.602 6.162-13.764 13.764-13.764s13.765 6.162 13.765 13.764M1458.33 1247.131c0 7.601-6.163 13.764-13.764 13.764-7.602 0-13.764-6.163-13.764-13.764 0-7.602 6.162-13.764 13.764-13.764 7.602-.001 13.764 6.162 13.764 13.764"></path>
      </g>
      <defs>
        <path
          id="SVGID_00000121249001163443297940000008741741689845657240_"
          d="m3048.633 1072.772-1646.955-77.749c-84.251-3.976-151.017 60.487-148.508 143.828l27.253 901.373c2.431 80.783 72.046 143.537 154.525 140.386l1615.573-61.765c63.653-2.432 113.806-61.007 113.806-130.995v-779.267c0-71.898-50.972-132.756-115.694-135.811"
        ></path>
      </defs>
      <clipPath id="SVGID_00000136400550076270223540000004012807085274940320_">
        <use
          xlinkHref="#SVGID_00000121249001163443297940000008741741689845657240_"
          overflow="visible"
        ></use>
      </clipPath>
      <g
        clipPath="url(#SVGID_00000136400550076270223540000004012807085274940320_)"
        opacity="0.5"
      >
        <path
          fill="#FFF"
          d="m1074.295 2172.087 295.074-1276.601 9.483 2.192-295.074 1276.6zM1401.812 2172.284l209.71-1276.625 9.607 1.579-209.71 1276.625zM1729.361 2172.587l124.03-1276.591 9.688.941-124.03 1276.592zM2056.834 2172.92l38.57-1276.535 9.731.294-38.57 1276.535z"
        ></path>
        <path
          fill="#FFF"
          d="M1150.436 1007.684H3270.84v9.736H1150.436zM1051.556 1355.843H3369.72v9.735H1051.556zM952.678 1704.001h2515.92v9.735H952.678zM853.797 2052.159h2713.68v9.735H853.797z"
        ></path>
        <path
          fill="#FFF"
          d="m2337.776 896.621 9.73-.355 46.626 1276.539-9.73.355zM2580.023 897.028l9.684-1.001 132.061 1276.594-9.683 1.001zM2822.23 897.268l9.598-1.636 217.557 1276.596-9.598 1.635zM3064.451 897.752l9.474-2.248 302.952 1276.621-9.474 2.249z"
        ></path>
      </g>
      <path
        fill="#FFF"
        d="m2553.147 1161.317-29.028-50.11c-4.426-7.677-12.614-12.168-21.475-12.168h-57.996c-8.862 0-17.049 4.491-21.475 12.168l-29.028 50.229a24.68 24.68 0 0 0 0 24.703l29.028 51.272c4.426 7.677 12.614 13.331 21.475 13.331h57.996c8.862 0 17.049-5.684 21.475-13.361l29.028-50.826c4.418-7.661 4.418-17.575 0-25.238"
      ></path>
      <path
        fill="#BDD0FB"
        d="m2543.556 1160.788-25.057-42.193c-3.82-6.626-10.887-9.442-18.537-9.442h-50.061c-7.649 0-14.717 2.816-18.537 9.442l-25.057 42.826c-3.813 6.615-3.813 14.443 0 21.058l25.057 45.451c3.82 6.626 10.888 12.7 18.537 12.7h50.061c7.649 0 14.717-6.232 18.537-12.858l25.057-44.535c3.814-6.615 3.814-15.835 0-22.449"
      ></path>
      <path
        fill="#FFF"
        d="m2794.26 1161.317-29.028-50.11c-4.426-7.677-12.613-12.168-21.475-12.168h-57.996c-8.861 0-17.049 4.491-21.475 12.168l-29.028 50.229a24.68 24.68 0 0 0 0 24.703l29.028 51.272c4.426 7.677 12.614 13.331 21.475 13.331h57.996c8.862 0 17.049-5.684 21.475-13.361l29.028-50.826c4.417-7.661 4.417-17.575 0-25.238"
      ></path>
      <path
        fill="#BDD0FB"
        d="m2784.668 1160.788-25.057-42.193c-3.821-6.626-10.887-9.442-18.537-9.442h-50.061c-7.649 0-14.717 2.816-18.537 9.442l-25.056 42.826c-3.814 6.615-3.814 14.443 0 21.058l25.056 45.451c3.821 6.626 10.888 12.7 18.537 12.7h50.061c7.649 0 14.716-6.232 18.537-12.858l25.057-44.535c3.814-6.615 3.814-15.835 0-22.449"
      ></path>
      <path
        fill="#FFF"
        d="m3035.372 1161.317-29.028-50.11c-4.426-7.677-12.614-12.168-21.475-12.168h-57.996c-8.862 0-17.049 4.491-21.475 12.168l-29.028 50.229a24.68 24.68 0 0 0 0 24.703l29.028 51.272c4.426 7.677 12.613 13.331 21.475 13.331h57.996c8.861 0 17.049-5.684 21.475-13.361l29.028-50.826c4.418-7.661 4.418-17.575 0-25.238"
      ></path>
      <path
        fill="#BDD0FB"
        d="m3025.781 1160.788-25.056-42.193c-3.821-6.626-10.888-9.442-18.537-9.442h-50.061c-7.649 0-14.716 2.816-18.537 9.442l-25.057 42.826c-3.813 6.615-3.813 14.443 0 21.058l25.057 45.451c3.821 6.626 10.888 12.7 18.537 12.7h50.061c7.649 0 14.716-6.232 18.537-12.858l25.056-44.535c3.813-6.615 3.813-15.835 0-22.449"
      ></path>
      <g fill="#91B3FA">
        <path d="M1900.131 1997.563c0 12.046-9.765 21.811-21.811 21.811h-515.995c-12.046 0-21.811-9.765-21.811-21.811v-.203c0-12.046 9.765-21.811 21.811-21.811h515.995c12.046 0 21.811 9.765 21.811 21.811zM1832.707 2082.105c0 11.783-10.136 21.549-20.73 21.549h-453.484c-10.594 0-17.98-9.766-17.98-21.549v-.935c0-11.783 7.386-24.713 17.98-24.713h453.484c10.594 0 20.73 12.929 20.73 24.713z"></path>
      </g>
      <ellipse
        cx="2029.667"
        cy="1899.974"
        fill="#91B3FA"
        rx="13.297"
        ry="52.047"
      ></ellipse>
      <linearGradient
        id="SVGID_00000047048765559194918580000011663887782613736064_"
        x1="2035.351"
        x2="2008.672"
        y1="1873.25"
        y2="1903.812"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2C50FF"></stop>
        <stop offset="0.996" stopColor="#1A1B80"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000047048765559194918580000011663887782613736064_)"
        d="M2048.463 1881.963a2.564 2.564 0 0 1-2.564 2.564h-31.954a2.564 2.564 0 0 1-2.564-2.564v-4.985a2.564 2.564 0 0 1 2.564-2.564h31.954a2.564 2.564 0 0 1 2.564 2.564z"
      ></path>
      <ellipse
        cx="2084.374"
        cy="1890.856"
        fill="#91B3FA"
        rx="13.297"
        ry="52.047"
      ></ellipse>
      <linearGradient
        id="SVGID_00000129202407270925629840000011498322895790603395_"
        x1="2089.29"
        x2="2062.612"
        y1="1863.137"
        y2="1893.698"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2C50FF"></stop>
        <stop offset="0.996" stopColor="#1A1B80"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000129202407270925629840000011498322895790603395_)"
        d="M2102.402 1871.849a2.564 2.564 0 0 1-2.564 2.564h-31.955a2.564 2.564 0 0 1-2.564-2.564v-4.985a2.564 2.564 0 0 1 2.564-2.564h31.955a2.564 2.564 0 0 1 2.564 2.564z"
      ></path>
      <linearGradient
        id="SVGID_00000085214402723742915650000012193412542994901140_"
        x1="2123.18"
        x2="1888.643"
        y1="1896.263"
        y2="2164.933"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9CFFFB"></stop>
        <stop offset="1" stopColor="#9BFFFA"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000085214402723742915650000012193412542994901140_)"
        d="M2147.818 1999.147H2020.9c-6.11 0-11.854-.708-15.319 4.398l-23.706 35.032c-.913 1.459-2.645 1.024-4.376 1.024h-.091c-1.642 0 .261-4.761.261-6.584v-94.552c0-7.476 3.927-13.859 11.402-14.862l60.046-8.753c3.92-.547 7.18-2.279 9.915-5.106l79.131-78.504 5.375 92.341z"
      ></path>
      <path
        fill="#91B3FA"
        d="M2147.818 1999.147H2020.9c-6.11 0-11.854-.708-15.319 4.398l-23.706 33.655c-.913 1.459-2.645 1.145-4.376.598l34.192-67.587c5.197-10.03 15.5-18.261 26.714-18.261h32.277c5.015 0 9.938.278 14.314-2.092l58.445-27.053z"
      ></path>
      <ellipse
        cx="2320.675"
        cy="1899.974"
        fill="#91B3FA"
        rx="13.297"
        ry="52.047"
      ></ellipse>
      <linearGradient
        id="SVGID_00000007415650929367005440000004446934866156958097_"
        x1="816.586"
        x2="789.907"
        y1="1873.25"
        y2="1903.812"
        gradientTransform="matrix(-1 0 0 1 3131 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2C50FF"></stop>
        <stop offset="0.996" stopColor="#1A1B80"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000007415650929367005440000004446934866156958097_)"
        d="M2338.385 1876.978a2.564 2.564 0 0 0-2.564-2.564h-31.955a2.564 2.564 0 0 0-2.564 2.564v4.985a2.564 2.564 0 0 0 2.564 2.564h31.955a2.564 2.564 0 0 0 2.564-2.564z"
      ></path>
      <ellipse
        cx="2265.969"
        cy="1890.856"
        fill="#91B3FA"
        rx="13.297"
        ry="52.047"
      ></ellipse>
      <linearGradient
        id="SVGID_00000039829818476986021800000000527970808299171746_"
        x1="870.525"
        x2="843.846"
        y1="1863.137"
        y2="1893.698"
        gradientTransform="matrix(-1 0 0 1 3131 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2C50FF"></stop>
        <stop offset="0.996" stopColor="#1A1B80"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000039829818476986021800000000527970808299171746_)"
        d="M2284.447 1866.864a2.564 2.564 0 0 0-2.564-2.564h-31.954a2.564 2.564 0 0 0-2.564 2.564v4.985a2.564 2.564 0 0 0 2.564 2.564h31.954a2.564 2.564 0 0 0 2.564-2.564z"
      ></path>
      <linearGradient
        id="SVGID_00000002378305183110160800000002330695278411255964_"
        x1="904.689"
        x2="670.052"
        y1="1896.824"
        y2="2165.611"
        gradientTransform="matrix(-1 0 0 1 3131 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9CFFFB"></stop>
        <stop offset="1" stopColor="#9BFFFA"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000002378305183110160800000002330695278411255964_)"
        d="M2202.525 1999.147h126.918c6.11 0 11.853-.708 15.318 4.398l23.706 35.032c.912 1.459 2.644 1.024 4.376 1.024h.092c1.641 0-4.209-4.761-4.209-6.584v-94.552c0-7.476-1.953-13.859-9.429-14.862l-59.058-8.753c-3.92-.547-6.687-2.279-9.423-5.106l-78.884-78.504-5.252 92.341z"
      ></path>
      <path
        fill="#91B3FA"
        d="M2202.525 1999.147h126.918c6.11 0 11.853-.708 15.318 4.398l23.706 33.655c.912 1.459 2.644 1.145 4.376.598l-34.192-67.587c-5.196-10.03-15.499-18.261-26.714-18.261h-32.277c-5.015 0-9.938.278-14.314-2.092l-58.445-27.053z"
      ></path>
      <linearGradient
        id="SVGID_00000103981389169713785400000011934512746045902514_"
        x1="2119.576"
        x2="2194.26"
        y1="2014.201"
        y2="2099.755"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2C50FF"></stop>
        <stop offset="0.996" stopColor="#1A1B80"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000103981389169713785400000011934512746045902514_)"
        d="M2139.938 1978.895a4.804 4.804 0 0 0-7.653-.605q-9.222 10.42-15.83 20.84c-.939 1.467 6.602 79.772 11.62 83.477 2.648 1.955 9.898 1.661 10.626 1.125 5.941-4.384 13.914-80.804 12.917-83.082q-4.764-10.879-11.68-21.755"
      ></path>
      <linearGradient
        id="SVGID_00000100381912632160293430000000283523827746997673_"
        x1="2205.726"
        x2="2280.41"
        y1="2014.201"
        y2="2099.755"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2C50FF"></stop>
        <stop offset="0.996" stopColor="#1A1B80"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000100381912632160293430000000283523827746997673_)"
        d="M2226.088 1978.895a4.807 4.807 0 0 0-7.653-.605q-9.222 10.42-15.83 20.84c-.939 1.467 6.602 79.772 11.62 83.477 2.648 1.955 9.898 1.661 10.626 1.125 5.941-4.384 13.915-80.804 12.917-83.082q-4.764-10.879-11.68-21.755"
      ></path>
      <path
        fill="#91B3FA"
        d="m2216.422 1760.539-35.654-145.502c-.294-1.143-.417-1.956-1.596-1.998s-1.803.697-2.177 1.817l-48.142 139.193c-.85 2.543-2.853 5.207-2.853 7.889v256.187c0 5.455 8.005 14.734 13.458 14.734h71.424c5.454 0 2.769-9.279 2.769-14.734v-251.391c.001-2.089 3.292-4.171 2.771-6.195"
      ></path>
      <linearGradient
        id="SVGID_00000178892983895077047640000011629238623209939603_"
        x1="2191.321"
        x2="2093.303"
        y1="1771.128"
        y2="1883.411"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2C50FF"></stop>
        <stop offset="0.996" stopColor="#1A1B80"></stop>
      </linearGradient>
      <ellipse
        cx="2171.372"
        cy="1793.98"
        fill="url(#SVGID_00000178892983895077047640000011629238623209939603_)"
        rx="31.152"
        ry="67.623"
      ></ellipse>
      <path
        fill="#91B3FA"
        d="M2185.527 1969.777a4.8 4.8 0 0 0-3.675-2.212 4.81 4.81 0 0 0-3.978 1.606q-9.222 10.42-15.83 20.841c-.939 1.467 6.602 79.773 11.62 83.477 2.648 1.955 9.898 1.661 10.626 1.125 5.941-4.384 13.915-80.804 12.917-83.082q-4.764-10.878-11.68-21.755"
      ></path>
      <linearGradient
        id="SVGID_00000073682490530418878220000008621736179799573912_"
        x1="2151.517"
        x2="2241.477"
        y1="1898.951"
        y2="2002.004"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2C50FF"></stop>
        <stop offset="0.996" stopColor="#1A1B80"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000073682490530418878220000008621736179799573912_)"
        d="M2126 1891.269h87.651v57.31H2126z"
      ></path>
      <linearGradient
        id="SVGID_00000024694414080045821790000017192247201469629342_"
        x1="-4258.866"
        x2="-4209.141"
        y1="10987.832"
        y2="10838.657"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000024694414080045821790000017192247201469629342_)"
        d="m1746.321 1302.648 31.735-66.341c1.674-3.499-1.014-6.145-5.814-5.992l-1.844.058c-4.545.145-9.53 2.815-11.348 6.06l-22.472 40.093c-2.441 4.355-7.323 7.909-12.443 9.051l-33.804 7.538-24.789 37.744 64.307-15.077c7.053-1.654 13.412-6.737 16.472-13.134"
      ></path>
      <linearGradient
        id="SVGID_00000031908583154582756990000007773602196922480804_"
        x1="-4148.526"
        x2="-4098.802"
        y1="11024.639"
        y2="10875.464"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000031908583154582756990000007773602196922480804_)"
        d="m1543.634 1236.307 31.735 66.341c3.06 6.397 9.419 11.48 16.472 13.134l64.307 15.077-24.788-37.744-33.805-7.538c-5.121-1.142-10.002-4.696-12.443-9.051l-22.472-40.093c-1.819-3.245-6.803-5.915-11.348-6.06l-1.844-.058c-4.801-.153-7.488 2.493-5.814 5.992"
      ></path>
      <linearGradient
        id="SVGID_00000093897970891797439940000004790085237521507745_"
        x1="1410.816"
        x2="1348.84"
        y1="630.965"
        y2="1337.5"
        gradientTransform="translate(266.575 484.378)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#E1473D"></stop>
        <stop offset="1" stopColor="#E9605A"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000093897970891797439940000004790085237521507745_)"
        d="M1704.431 1380.171c-9.881 25.158-28.76 49.245-42.7 49.245h-.6c-13.94 0-32.819-24.087-42.7-49.245-8.413-24.651-16.826-49.173-25.239-73.824-10.066-25.485 19.183-30.305 67.337-30.028.601.002 1.202.035 1.803.032 48.155-.277 77.404 4.494 67.337 29.979-8.412 24.653-16.825 49.19-25.238 73.841"
      ></path>
      <linearGradient
        id="SVGID_00000124146155629446945460000009642058441800980914_"
        x1="-4234.977"
        x2="-4185.252"
        y1="10995.811"
        y2="10846.636"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000124146155629446945460000009642058441800980914_)"
        d="M1631.828 1381.285c0-17.054 13.825-30.879 30.879-30.879s30.879 13.825 30.879 30.879-13.825 30.879-30.879 30.879c-17.053-.001-30.879-13.825-30.879-30.879"
      ></path>
      <path
        fill="#91B3FA"
        d="M1639.484 1383.07c0-12.685 10.283-22.968 22.968-22.968s22.968 10.283 22.968 22.968-10.283 22.968-22.968 22.968c-12.685.001-22.968-10.282-22.968-22.968"
      ></path>
      <linearGradient
        id="SVGID_00000002379575619451797230000006585228893316792753_"
        x1="-4203.056"
        x2="-4153.331"
        y1="11006.431"
        y2="10857.256"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000002379575619451797230000006585228893316792753_)"
        d="M1653.436 1246.975c.083-3.665 2.824-7.046 6.174-7.7 3.404-.664 6.354 1.693 6.546 5.418l3.249 62.938c.38 7.363-3.544 13.681-8.672 13.681-5.007 0-8.843-6.056-8.683-13.13z"
      ></path>
      <linearGradient
        id="SVGID_00000075142234833551316320000010106843717253784742_"
        x1="-1700.157"
        x2="-1691.729"
        y1="20147.99"
        y2="19969.316"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000075142234833551316320000010106843717253784742_)"
        d="m2826.887 1396.166 28.688-59.971c1.513-3.164-.916-5.556-5.256-5.417l-1.667.053c-4.108.131-8.614 2.545-10.258 5.478l-20.314 36.243c-2.207 3.938-6.62 7.15-11.249 8.182l-30.559 6.815-22.408 34.119 58.133-13.63c6.375-1.495 12.124-6.09 14.89-11.872"
      ></path>
      <linearGradient
        id="SVGID_00000098221843249940429740000002265747861661713028_"
        x1="-1589.745"
        x2="-1581.318"
        y1="20153.217"
        y2="19974.545"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000098221843249940429740000002265747861661713028_)"
        d="m2643.661 1336.195 28.688 59.971c2.766 5.783 8.515 10.378 14.89 11.872l58.133 13.63-22.408-34.119-30.559-6.815c-4.629-1.032-9.042-4.245-11.248-8.182l-20.314-36.243c-1.644-2.933-6.15-5.347-10.258-5.478l-1.667-.053c-4.341-.139-6.77 2.253-5.257 5.417"
      ></path>
      <linearGradient
        id="SVGID_00000081642259728067723990000005144143016695732638_"
        x1="2498.281"
        x2="2441.196"
        y1="740.03"
        y2="1390.801"
        gradientTransform="translate(266.575 484.378)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#E1473D"></stop>
        <stop offset="1" stopColor="#E9605A"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000081642259728067723990000005144143016695732638_)"
        d="M2789.019 1467.713c-8.932 22.742-25.999 45.983-38.6 45.983h-.542c-12.602 0-29.668-23.241-38.6-45.983-7.605-22.285-15.21-45.185-22.816-67.47-9.099-23.038 17.341-27.762 60.872-27.511.543.002 1.087-.152 1.63-.154 43.531-.25 69.971 4.704 60.871 27.743-7.605 22.284-15.21 45.107-22.815 67.392"
      ></path>
      <linearGradient
        id="SVGID_00000088837936602473403500000009677348967427211161_"
        x1="-1650.832"
        x2="-1642.405"
        y1="20150.365"
        y2="19971.691"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000088837936602473403500000009677348967427211161_)"
        d="M2723.388 1467.252c0-15.417 12.498-27.914 27.914-27.914s27.914 12.498 27.914 27.914-12.498 27.914-27.914 27.914-27.914-12.498-27.914-27.914"
      ></path>
      <path
        fill="#91B3FA"
        d="M2730.309 1468.866c0-11.467 9.295-20.763 20.762-20.763s20.763 9.296 20.763 20.763-9.296 20.763-20.763 20.763-20.762-9.296-20.762-20.763"
      ></path>
      <linearGradient
        id="SVGID_00000092415764798399144360000005024841064225985425_"
        x1="-1645.287"
        x2="-1636.859"
        y1="20150.58"
        y2="19971.906"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000092415764798399144360000005024841064225985425_)"
        d="M2742.921 1345.838c.075-3.313 2.553-6.37 5.581-6.96 3.077-.6 5.744 1.53 5.918 4.898l2.938 56.894c.343 6.656-3.204 12.367-7.841 12.367-4.526 0-7.993-5.474-7.849-11.869z"
      ></path>
      <linearGradient
        id="SVGID_00000151510492618202376060000000022264201899126170_"
        x1="-4618.462"
        x2="-4576.322"
        y1="8085.058"
        y2="7982.236"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000151510492618202376060000000022264201899126170_)"
        d="m2245.677 1181.458 22.642-47.333c1.195-2.497-.723-4.384-4.148-4.276l-1.316.042c-3.243.103-6.799 2.009-8.096 4.324l-16.033 28.606c-1.742 3.107-5.225 5.643-8.878 6.458l-24.119 5.379-17.686 26.929 45.883-10.757c5.031-1.181 9.568-4.808 11.751-9.372"
      ></path>
      <linearGradient
        id="SVGID_00000145771361009280623710000010919175332790022335_"
        x1="-4543.494"
        x2="-4501.354"
        y1="8115.761"
        y2="8012.94"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000145771361009280623710000010919175332790022335_)"
        d="m2101.063 1134.125 22.642 47.333c2.183 4.565 6.72 8.191 11.753 9.371l45.882 10.757-17.686-26.929-24.119-5.379c-3.654-.815-7.136-3.35-8.878-6.458l-16.033-28.606c-1.298-2.315-4.854-4.22-8.097-4.324l-1.315-.042c-3.426-.108-5.343 1.78-4.149 4.277"
      ></path>
      <linearGradient
        id="SVGID_00000140712178730137367070000013914884843215395239_"
        x1="1930.155"
        x2="1885.056"
        y1="561.417"
        y2="1075.546"
        gradientTransform="translate(266.575 484.378)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#E1473D"></stop>
        <stop offset="1" stopColor="#E9605A"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000140712178730137367070000013914884843215395239_)"
        d="M2215.79 1237.988c-7.05 17.95-20.52 36.353-30.466 36.353h-.428c-9.946 0-23.416-18.403-30.466-36.353-6.003-17.589-12.005-35.693-18.008-53.282-7.182-18.183 13.687-21.926 48.044-21.729.429.001.858-.128 1.287-.129 34.358-.198 55.226 3.739 48.044 21.922-6.002 17.589-12.005 35.63-18.007 53.218"
      ></path>
      <linearGradient
        id="SVGID_00000070801598958473436300000012443472506853701557_"
        x1="-4606.824"
        x2="-4564.684"
        y1="8089.831"
        y2="7987.01"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000070801598958473436300000012443472506853701557_)"
        d="M2163.989 1237.564c0-12.168 9.864-22.032 22.032-22.032s22.032 9.864 22.032 22.032-9.864 22.031-22.032 22.031-22.032-9.864-22.032-22.031"
      ></path>
      <circle
        cx="2185.839"
        cy="1238.839"
        r="16.387"
        fill="#91B3FA"
        transform="rotate(-45.001 2185.835 1238.87)"
      ></circle>
      <linearGradient
        id="SVGID_00000157998533563189045790000017672154433905693098_"
        x1="-4580.404"
        x2="-4538.264"
        y1="8100.653"
        y2="7997.832"
        gradientTransform="rotate(180 1106.787 -990.24)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000157998533563189045790000017672154433905693098_)"
        d="M2179.406 1141.736c.059-2.615 2.015-5.027 4.405-5.494 2.428-.474 4.533 1.208 4.67 3.866l2.319 44.905c.271 5.253-2.529 9.761-6.188 9.761-3.573 0-6.309-4.321-6.195-9.368z"
      ></path>
      <linearGradient
        id="SVGID_00000095307897103143294970000007459928280673675408_"
        x1="2732.988"
        x2="2790.223"
        y1="1796.147"
        y2="1320.501"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FF928E"></stop>
        <stop offset="1" stopColor="#FE7062"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000095307897103143294970000007459928280673675408_)"
        d="m2717.839 1713.172 10.522-162.029 39.981 162.029 92.587-23.147-71.545 73.649 37.877 101.005-61.024-62.062-67.336 102.043 18.584-127.693-98.547 9.854 92.589-45.242-73.65-62.075z"
      ></path>
      <path
        fill="#FFF"
        d="m2121.991 1266.219 6.138-94.519 23.323 94.519 54.011-13.503-41.736 42.963 22.096 58.921-35.598-36.204-39.281 59.527 10.841-74.49-57.487 5.749 54.011-26.392-42.964-36.212z"
      ></path>
      <path
        fill="#BDD0FB"
        d="M2178.254 1603.634c-1.388 0-1.686-1.123-1.686-2.512v-10.047c0-1.388.297-2.511 1.686-2.511 1.388 0 1.686 1.123 1.686 2.511v10.047c0 1.389-.298 2.512-1.686 2.512M2178.254 1563.449c-1.388 0-1.686-1.124-1.686-2.512v-25.116c0-1.388.297-2.511 1.686-2.511 1.388 0 1.686 1.123 1.686 2.511v25.116c0 1.388-.298 2.512-1.686 2.512m0-50.232c-1.388 0-1.686-1.123-1.686-2.511v-25.116c0-1.388.297-2.512 1.686-2.512 1.388 0 1.686 1.123 1.686 2.512v25.116c0 1.388-.298 2.511-1.686 2.511m0-50.231c-1.388 0-1.686-1.124-1.686-2.512v-25.116c0-1.388.297-2.512 1.686-2.512 1.388 0 1.686 1.124 1.686 2.512v25.116c0 1.388-.298 2.512-1.686 2.512m0-50.232c-1.388 0-1.686-1.124-1.686-2.512v-25.116c0-1.388.297-2.511 1.686-2.511 1.388 0 1.686 1.123 1.686 2.511v25.116c0 1.388-.298 2.512-1.686 2.512m0-50.232c-1.388 0-1.686-1.123-1.686-2.511v-25.116c0-1.388.297-2.512 1.686-2.512 1.388 0 1.686 1.123 1.686 2.512v25.116c0 1.388-.298 2.511-1.686 2.511m0-50.232c-1.388 0-1.686-1.123-1.686-2.512v-5.023c0-1.388.297-2.512 1.686-2.512 1.388 0 1.686 1.123 1.686 2.512v5.023c0 1.389-.298 2.512-1.686 2.512M2178.254 1297.22c-1.388 0-1.686-1.123-1.686-2.511v-15.07c0-1.388.297-2.512 1.686-2.512 1.388 0 1.686 1.123 1.686 2.512v15.07c0 1.389-.298 2.511-1.686 2.511"
      ></path>
      <path
        fill="#FFF"
        d="m1456.554 1555.724 7.8-120.113 29.639 120.113 68.636-17.158-53.037 54.597 28.078 74.877-45.237-46.008-49.918 75.646 13.777-94.661-73.054 7.305 68.636-33.538-54.597-46.018z"
      ></path>
      <path
        fill="#BDD0FB"
        d="M1112.164 2014.959s51.248-319.14-84.545-424.931c-135.792-105.791-408.885-197.578-356.742-658.755 30.526-269.994 39.904-388.581 42.432-440.502.987-20.254 20.976-33.987 40.214-27.577 136.081 45.344 575.04 215.474 627.963 538.12 62.74 382.5-269.322 1013.645-269.322 1013.645"
      ></path>
      <path
        fill="#91B3FA"
        d="M844.105 605.829c134.679 157.626 278.979 423.774 340.492 678.642 6.694 41.534 30.615 131.181 25.486 283.97-3.366 127.552-28.305 259.422-56.971 345.058-1.813 5.414-7.67 8.333-13.084 6.521-9.309-3.115-6.638-13.22-6.596-12.884 39.524-147.597 57.09-335.617 36.412-510.243-27.561-214.716-124.62-432.478-230.105-620.326l-10.958-18.575c-82.258-141.637-103.12-145.44-92.582-153.096 2.489-1.811 5.937-1.36 7.906.933M1065.008 2614.624c17.217 14.372-124.608-293.131-89.925-713.178 16.344-197.937 74.293-436.777-11.769-555.307-71.627-98.649-390.854-125.432-495.93-131.641-13.13-.776-21.241 14.067-13.465 24.675 38.337 52.298 118.172 173.068 121.524 275.939 4.418 135.591 105.365 245.817 208.061 294.069 102.696 48.251 157.612 84.142 168.022 269.777s64.562 494.83 113.482 535.666"
      ></path>
      <path
        fill="#7D97F4"
        d="M595.746 1302.7c127.018 36.737 250.467 128.941 293.537 255.835l4.037 12.091c9.235 28.294 52.917 224.062 65.502 380.57a3.545 3.545 0 0 1-3.249 3.817c-3.314.266-4.025-3.483-3.805-3.114-13.431-89.96-36.088-205.391-55.366-292.281-20.317-94.64-35.002-158.958-115.099-236.703-89.634-84.552-187.581-114.937-187.298-114.803a2.84 2.84 0 0 1-1.727-3.631 2.85 2.85 0 0 1 3.468-1.781"
      ></path>
      <linearGradient
        id="SVGID_00000124841966453160053400000016970630278235991228_"
        x1="1885.627"
        x2="1841.803"
        y1="1659.529"
        y2="3308.04"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000124841966453160053400000016970630278235991228_)"
        d="m3243.44 2910.716-98.542-870.525-.151.077c-21.482-299.591-271.161-536.635-576.226-536.635-183.103 0-346.2 84.896-452.082 217.503h-529.537c-105.882-132.607-268.979-217.503-452.082-217.503-305.065 0-554.744 236.735-576.227 536.326l-.149.077-98.543 870.603c-11.517 101.729 45.042 199.009 139.157 239.301l97.786 41.883c91.435 39.144 197.648 15.352 264.004-58.735l466.911-521.59h847.821l466.91 521.6c66.358 74.087 172.571 97.748 264.005 58.604l97.787-41.793c94.116-40.293 150.673-137.465 139.158-239.193"
      ></path>
      <linearGradient
        id="SVGID_00000047034925381748902340000006817244479839995567_"
        x1="762.044"
        x2="1516.638"
        y1="2086.487"
        y2="2086.487"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4F52FF"></stop>
        <stop offset="1" stopColor="#4042E2"></stop>
      </linearGradient>
      <circle
        cx="1139.341"
        cy="2086.488"
        r="377.297"
        fill="url(#SVGID_00000047034925381748902340000006817244479839995567_)"
        opacity="0.53"
      ></circle>
      <linearGradient
        id="SVGID_00000126301231684848914050000007810856682977168300_"
        x1="2215.198"
        x2="2969.791"
        y1="2086.487"
        y2="2086.487"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4F52FF"></stop>
        <stop offset="1" stopColor="#4042E2"></stop>
      </linearGradient>
      <circle
        cx="2592.494"
        cy="2086.488"
        r="377.297"
        fill="url(#SVGID_00000126301231684848914050000007810856682977168300_)"
        opacity="0.53"
      ></circle>
      <linearGradient
        id="SVGID_00000043425041597534542640000007629455738896612277_"
        x1="1979.305"
        x2="3625.146"
        y1="2654.225"
        y2="608.046"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FF928E"></stop>
        <stop offset="1" stopColor="#FE7062"></stop>
      </linearGradient>
      <circle
        cx="2593.668"
        cy="1890.423"
        r="87.321"
        fill="url(#SVGID_00000043425041597534542640000007629455738896612277_)"
      ></circle>
      <circle
        cx="2593.668"
        cy="2266.068"
        r="87.321"
        fill="#7D97F4"
        transform="rotate(-23.865 2593.345 2265.833)"
      ></circle>
      <linearGradient
        id="SVGID_00000044153707001387238680000018336801586556016305_"
        x1="2027.625"
        x2="1928.771"
        y1="159.007"
        y2="514.881"
        gradientTransform="rotate(90 2110.167 2672.5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#E1473D"></stop>
        <stop offset="1" stopColor="#E9605A"></stop>
      </linearGradient>
      <circle
        cx="2781.49"
        cy="2078.245"
        r="87.321"
        fill="url(#SVGID_00000044153707001387238680000018336801586556016305_)"
      ></circle>
      <circle cx="2405.845" cy="2078.245" r="87.321" fill="#91B3FA"></circle>
      <linearGradient
        id="SVGID_00000137113207352835998690000011671794544441417373_"
        x1="1278.004"
        x2="788.762"
        y1="1799.934"
        y2="2647.955"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000137113207352835998690000011671794544441417373_)"
        d="M1314.575 2038.445h-125.339l-17.156-163.457c-1.75-17.109-16.161-25.982-33.357-25.982h-41.688c-9.242 0-18.074-.192-24.411 6.536-6.335 6.729-9.615 13.643-9.061 22.867l9.73 160.036H917.026c-18.519 0-36.891 15.27-36.891 33.791v31.789c0 18.521 18.372 33.875 36.891 33.875h162.199l9.969 166.785c1.063 17.708 15.734 32.126 33.472 32.126h59.79c9.487 0 18.532-4.791 24.889-11.834s9.435-16.662 8.469-26.101l-16.458-160.976h115.218c18.519 0 24.949-15.354 24.949-33.875v-31.789c.001-18.521-6.428-33.791-24.948-33.791"
      ></path>
      <linearGradient
        id="SVGID_00000044168069622159782940000014270539199691937704_"
        x1="1745.509"
        x2="1642.99"
        y1="1903.37"
        y2="2081.069"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4F52FF"></stop>
        <stop offset="1" stopColor="#4042E2"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000044168069622159782940000014270539199691937704_)"
        d="M1784.705 1962.67c0 10.462-8.481 18.944-18.944 18.944h-108.927c-10.462 0-18.944-8.482-18.944-18.944s8.481-18.944 18.944-18.944h108.927c10.463 0 18.944 8.481 18.944 18.944"
        opacity="0.53"
      ></path>
      <linearGradient
        id="SVGID_00000026156039690383779530000008387437261888292504_"
        x1="2001.251"
        x2="1898.732"
        y1="1903.37"
        y2="2081.069"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4F52FF"></stop>
        <stop offset="1" stopColor="#4042E2"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000026156039690383779530000008387437261888292504_)"
        d="M2040.447 1962.67c0 10.462-8.481 18.944-18.944 18.944h-108.927c-10.462 0-18.944-8.482-18.944-18.944s8.481-18.944 18.944-18.944h108.927c10.463 0 18.944 8.481 18.944 18.944"
        opacity="0.53"
      ></path>
      <linearGradient
        id="SVGID_00000120522762281460926530000017831464028679084676_"
        x1="1405.428"
        x2="1135.732"
        y1="624.872"
        y2="1231.687"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FF928E"></stop>
        <stop offset="1" stopColor="#FE7062"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000120522762281460926530000017831464028679084676_)"
        d="M961.045 1095.914c17.076-11.741 35.956-20.593 55.972-26.04 7.518-2.046 48.111 9.752 73.205 9.175 34.446-.792 69.508-16.468 97.901-9.392 28.204 7.029 51.288 16.209 64.616 25.688 39.895 28.375 63.319 81.285 77.594 133.693-27.029 27.746-61.043 40.077-61.043 40.077l-6.286 254.745-150.635 77.071-171.932-86.32 6.159-248.947s-54.597-25.426-58.603-34.674 24.883-101.958 73.052-135.076"
      ></path>
      <linearGradient
        id="SVGID_00000158712192321633277580000006209222430329868957_"
        x1="1109.322"
        x2="1097.391"
        y1="1413.58"
        y2="1520.21"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2C50FF"></stop>
        <stop offset="0.996" stopColor="#1A1B80"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000158712192321633277580000006209222430329868957_)"
        d="M971.846 1425.633s-23.016.398-40.092 13.58c-14.103 10.887-28.373 64.248-18.057 99.553 22.806 78.051 222.852 39.601 279.601 36.256s108.579-31.382 91.692-99.548-313.144-49.841-313.144-49.841"
      ></path>
      <linearGradient
        id="SVGID_00000143599139968652058210000004855257312184188058_"
        x1="1204.145"
        x2="1068.413"
        y1="75.314"
        y2="355.089"
        gradientTransform="rotate(9.249 -2143.816 963.018)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000143599139968652058210000004855257312184188058_)"
        d="M1042.634 860.639c-.693 1.313-44.343 33.433-44.343 33.433s9.639 68.961 48.783 96.192c43.036 29.939 103.814-24.66 103.814-24.66s48.369 11.498 74.091-89.188c5.901-23.1-28.163-25.052-69.276-20.798-53.017 5.488-113.069 5.021-113.069 5.021"
      ></path>
      <linearGradient
        id="SVGID_00000046335388070803315590000001995386908567354809_"
        x1="853.154"
        x2="1139.404"
        y1="1175.342"
        y2="1866.802"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000046335388070803315590000001995386908567354809_)"
        d="M890.536 1229.878s-73.163 202.837 93.675 286.202c34.642-4.602 21.318-62.133 21.318-62.133s-72.492-16.043-50.721-185.247c-20.044-16.491-64.272-38.822-64.272-38.822"
      ></path>
      <linearGradient
        id="SVGID_00000016068156825106740100000013436804475673849271_"
        x1="-5527.681"
        x2="-5241.431"
        y1="1173.856"
        y2="1865.316"
        gradientTransform="matrix(-1 0 0 1 -4167.074 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000016068156825106740100000013436804475673849271_)"
        d="M1326.815 1229.878s50.344 211.734-93.675 286.202c-34.642-4.602-21.318-62.133-21.318-62.133s58.425-28.773 50.721-185.247c20.044-16.491 64.272-38.822 64.272-38.822"
      ></path>
      <path
        fill="#91B3FA"
        d="M1246.18 1509.239H935.738a3.715 3.715 0 0 1-3.704-3.704v-207.249a3.715 3.715 0 0 1 3.704-3.704h310.442a3.715 3.715 0 0 1 3.704 3.704v207.249a3.715 3.715 0 0 1-3.704 3.704"
      ></path>
      <path
        fill="#FFF"
        d="M1227.844 1513.906H954.075c-14.726 0-26.71-11.981-26.71-26.707v-170.577c0-14.726 11.984-26.71 26.71-26.71h273.769c14.726 0 26.71 11.984 26.71 26.71v170.577c0 14.726-11.984 26.707-26.71 26.707m-273.769-214.657c-9.581 0-17.373 7.795-17.373 17.373v170.577c0 9.578 7.792 17.37 17.373 17.37h273.769c9.581 0 17.373-7.792 17.373-17.37v-170.577c0-9.578-7.792-17.373-17.373-17.373z"
      ></path>
      <linearGradient
        id="SVGID_00000101811095296440615340000016972424201465646218_"
        x1="992.125"
        x2="1106.685"
        y1="1990.066"
        y2="1990.066"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4F52FF"></stop>
        <stop offset="1" stopColor="#4042E2"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000101811095296440615340000016972424201465646218_)"
        d="M992.125 2026.597c11.699-36.932 60.104-109.86 60.104-109.86l10.123 1.778 44.333 18.983s-29.295 79.227-50.156 114.544c-2.129 3.604-4.615 6.585-7.331 9.05-1.605.513-3.231.981-4.909 1.342-25.263 5.435-46.84-12.932-52.164-35.837"
      ></path>
      <path
        fill="#7D97F4"
        d="M991.042 2030.301a80 80 0 0 1 1.083-3.704c5.325 22.905 26.901 41.272 52.163 35.836 1.678-.361 3.305-.829 4.909-1.342-23.902 21.693-66.568 1.085-58.155-30.79"
      ></path>
      <linearGradient
        id="SVGID_00000149347228633756283750000012803933418793813650_"
        x1="1008.939"
        x2="843.726"
        y1="2952.364"
        y2="3399.863"
        gradientTransform="rotate(-61.241 -246.458 2452.982)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000149347228633756283750000012803933418793813650_)"
        d="m1062.651 1893.753-6.968 13.064c-3.744 7.019-4.201 15.42-1.268 23.31s9.009 14.605 16.858 18.631c6.259 3.21 13.398 4.03 19.839 2.279s11.649-5.929 14.475-11.609l10.104-20.313z"
      ></path>
      <linearGradient
        id="SVGID_00000029748475497581217870000017961205361617112730_"
        x1="1695.322"
        x2="1245.289"
        y1="1438.177"
        y2="1630.326"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4F52FF"></stop>
        <stop offset="1" stopColor="#4042E2"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000029748475497581217870000017961205361617112730_)"
        d="M1181.453 1421.73c-42.838-3.956-68.627 29.72-69.625 69.882.416.937-6.785 212.613-58.182 411.769 18.782 3.799 37.257 10.593 54.337 19.237a145 145 0 0 1 10.959 6.25c66.661-137.77 101.79-265.526 131.105-412.042 8.771-45.607-21.115-90.712-68.594-95.096"
      ></path>
      <linearGradient
        id="SVGID_00000040534129367905644210000007233709800160310426_"
        x1="1193.297"
        x2="1326.499"
        y1="1885.796"
        y2="1885.796"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4F52FF"></stop>
        <stop offset="1" stopColor="#4042E2"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000040534129367905644210000007233709800160310426_)"
        d="M1326.476 1907.225c-20.71-32.794-85.994-91.049-85.994-91.049l-9.303 4.325-37.883 29.796s48.474 69.272 77.602 98.146c2.972 2.946 6.13 5.197 9.378 6.889 1.678.085 3.365.121 5.075.039 25.738-1.224 41.843-24.574 41.125-48.146"
      ></path>
      <path
        fill="#7D97F4"
        d="M1328.466 1910.537a80 80 0 0 0-1.99-3.313c.718 23.572-15.387 46.922-41.125 48.146-1.71.081-3.397.046-5.075-.039 28.581 14.885 64.441-16.057 48.19-44.794"
      ></path>
      <linearGradient
        id="SVGID_00000074420122757773053970000006699730086776256138_"
        x1="478.624"
        x2="313.507"
        y1="691.145"
        y2="1138.383"
        gradientTransform="rotate(44.798 -252.416 2415.532)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000074420122757773053970000006699730086776256138_)"
        d="m1224.567 1796.575 10.053 10.873c5.401 5.842 7.988 13.868 7.176 22.271s-4.953 16.473-11.491 22.393c-5.212 4.72-11.885 7.35-18.539 7.308s-12.743-2.753-16.917-7.533l-14.927-17.094z"
      ></path>
      <linearGradient
        id="SVGID_00000123429067026771913430000013590840115187405460_"
        x1="1140.233"
        x2="1281.823"
        y1="1994.513"
        y2="3026.097"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4F52FF"></stop>
        <stop offset="1" stopColor="#4042E2"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000123429067026771913430000013590840115187405460_)"
        d="M1185.429 1837.529q22.673-24.103 52.523-34.638c.799-.433 1.611-.805 2.414-1.219-5.593-10.521-12.661-24.271-21.75-42.531-66.3-134.1-108.454-202.695-141.906-315.257-12.995-43.729-55.623-65.401-98.094-40.818-37.915 21.945-45.528 65.264-27.412 101.134 54.196 107.314 155.15 250.728 231.011 336.904 1.067-1.197 2.127-2.404 3.214-3.575"
      ></path>
      <linearGradient
        id="SVGID_00000152242387215788644760000008082862026663444915_"
        x1="1104.44"
        x2="1100.631"
        y1="1232.434"
        y2="901.052"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000152242387215788644760000008082862026663444915_)"
        d="M1017.017 1069.874s15.222 65.964 89.124 64.594c75.272-1.37 81.982-64.811 81.982-64.811l-49.17-10.521-4.109-54.787-73.962 5.479-1.37 52.047z"
      ></path>
      <linearGradient
        id="SVGID_00000134250956362629412460000018116710178519660690_"
        x1="1064.974"
        x2="1076.839"
        y1="526.841"
        y2="238.481"
        gradientTransform="rotate(9.249 -2143.816 963.018)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000134250956362629412460000018116710178519660690_)"
        d="M1071.266 849.121s-84.76 164.185 15.785 204.019c70.92 28.096 115.385-109.032 102.123-146.172-20.506-57.427-34.011-50.613-34.011-50.613z"
      ></path>
      <linearGradient
        id="SVGID_00000002370577648760619570000009713265268031533714_"
        x1="1205.14"
        x2="1052.411"
        y1="35.938"
        y2="350.746"
        gradientTransform="rotate(9.249 -2143.816 963.018)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000002370577648760619570000009713265268031533714_)"
        d="M1209.571 834.181c41.873 11.509 6.087 102.323-56.955 80.847s-87.816-21.162-97.64-3.851-11.935 78.786-33.25 48.401c-23.369-33.313-36.399-100.178-7.747-121.733 25.607-19.264 31.582-49.131 62.296-48.096 40.767 1.374 72.294-16.697 90.696-3.664 21.134 14.967 11.789 39.628 42.6 48.096"
      ></path>
      <linearGradient
        id="SVGID_00000068638728413930976240000010925198477984724916_"
        x1="898.289"
        x2="916.882"
        y1="1434.445"
        y2="982.556"
        gradientTransform="rotate(-7.637 -1633.05 86.824)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000068638728413930976240000010925198477984724916_)"
        d="M1051.099 925.296s-18.077-19.45-20.971 3.992c-2.894 23.443 6.396 53.635 17.28 37.085s3.691-41.077 3.691-41.077"
      ></path>
      <path
        fill="#FFF"
        d="M1123.1 1367.659c1.751 11.835-4.59 22.578-16.425 24.329s-22.849-6.423-24.601-18.259 3.666-23.59 15.501-25.341 23.774 7.436 25.525 19.271"
      ></path>
      <linearGradient
        id="SVGID_00000132075919817535587380000006161292711728767405_"
        x1="1558.635"
        x2="1361.808"
        y1="692.172"
        y2="917.645"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000132075919817535587380000006161292711728767405_)"
        d="m1625.929 720.951-39.185-67.491c-5.975-10.363-17.027-16.273-28.989-16.273h-78.288c-11.962 0-23.014 5.911-28.989 16.273l-39.185 67.729a33.24 33.24 0 0 0 0 33.308l39.185 68.09c5.975 10.363 17.027 16.872 28.989 16.872h78.288c11.962 0 23.014-6.568 28.989-16.931l39.185-68.058c5.964-10.345 5.964-23.175 0-33.519"
      ></path>
      <path
        fill="#7D97F4"
        d="m1612.983 720.235-33.824-56.887c-5.157-8.945-14.697-12.677-25.023-12.677h-67.577c-10.326 0-19.866 3.732-25.023 12.677l-33.824 57.777c-5.148 8.929-5.148 19.48 0 28.409l33.824 63.696c5.157 8.945 14.697 19.486 25.023 19.486h67.577c10.326 0 19.865-10.763 25.023-19.708l33.824-61.293c5.147-8.929 5.147-22.551 0-31.48"
      ></path>
      <linearGradient
        id="SVGID_00000133490989266160814990000007600505424104236936_"
        x1="2016.437"
        x2="2016.437"
        y1="104.538"
        y2="458.513"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000133490989266160814990000007600505424104236936_)"
        d="M2358.613 715.545c0 20.473-13.975 32.891-30.803 32.891h-620.878c-16.828 0-32.671-12.418-32.671-32.891v-.369c0-20.473 15.843-34.164 32.671-34.164h620.878c16.828 0 30.803 13.691 30.803 34.164z"
      ></path>
      <path
        fill="#7D97F4"
        d="M2108.17 718.095c0 13.15-8.558 26.97-19.113 26.97H1713.39c-10.555 0-19.113-13.82-19.113-26.97s8.557-26.969 19.113-26.969h375.667c10.556-.001 19.113 13.819 19.113 26.969"
      ></path>
      <g fill="#FFF">
        <path d="M1545.658 703.506c0 14.859-12.046 30.9-26.904 30.9s-26.905-16.041-26.905-30.9 12.046-25.401 26.905-25.401 26.904 10.542 26.904 25.401M1485.365 791.614h65.678a15.9 15.9 0 0 0 15.141-11.053l3.534-11.046c2.588-8.088-1.62-16.845-9.595-19.76-26.729-9.773-54.367-8.519-82.68.902-7.932 2.639-12.333 11.123-10.144 19.191l2.722 10.032a15.9 15.9 0 0 0 15.344 11.734"></path>
      </g>
      <linearGradient
        id="SVGID_00000142885830312179592470000000943599468120852652_"
        x1="2310.743"
        x2="2236.168"
        y1="2871.216"
        y2="2984.673"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FF928E"></stop>
        <stop offset="1" stopColor="#FE7062"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000142885830312179592470000000943599468120852652_)"
        d="M2219.976 2922.662c16.288 3.24 109.108 58.473 109.108-4.674v-70.539s-113.788 74.282-109.108 75.213"
      ></path>
      <linearGradient
        id="SVGID_00000029727259492045308210000015522382480919946147_"
        x1="2220.967"
        x2="2227.341"
        y1="2658.53"
        y2="2828.077"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000029727259492045308210000015522382480919946147_)"
        d="M2278.066 2804.106s-40.793-68.839-45.893-136.403c-5.099-67.564 3.187-97.136-4.462-116.769s-44.83-94.204-46.53-71.791c-1.699 22.413 11.898 45.784 11.898 45.784s-23.587 18.763-19.547 40.368 12.323 23.796 12.323 23.796 1.275 46.742 3.4 46.742-37.394 234.562 6.799 239.661c44.193 5.1 79.888 36.544 82.012-71.388"
      ></path>
      <linearGradient
        id="SVGID_00000023267022933854780130000016279366561192083366_"
        x1="2090.291"
        x2="2000.913"
        y1="2431.801"
        y2="2557.905"
        gradientTransform="rotate(-4.93 2697.615 1445.785)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000023267022933854780130000016279366561192083366_)"
        d="M2161.491 2548.677c-.412-3.105-28.982-65.251-23.866-72.318s10.281-13.371 18.301 7.582 17.587 77.952 14.506 78.292-7.465-2.418-8.941-13.556"
      ></path>
      <linearGradient
        id="SVGID_00000077312028262173967300000001272641552090108045_"
        x1="1885.38"
        x2="1797.939"
        y1="3195.216"
        y2="3305.156"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000077312028262173967300000001272641552090108045_)"
        d="M1894.155 3225.742s-21.568-17.9-50.246-15.291c-19.993 3.502-43.448 21.185-58.569 34.305-2.637 2.287-3.523 5.929-2.226 9.123s4.505 5.265 8.04 5.192c37.495-.777 110.849-3.004 110.849-8.532-.001-7.425-7.848-24.797-7.848-24.797"
      ></path>
      <linearGradient
        id="SVGID_00000047773521427348666220000013147217877749602451_"
        x1="1901.592"
        x2="1850.811"
        y1="3174.617"
        y2="3238.464"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000047773521427348666220000013147217877749602451_)"
        d="m1909.014 3194.192-17.21 32.507s-38.881 3.187-37.606-12.11c1.275-15.298 10.836-33.782 10.836-33.782z"
      ></path>
      <linearGradient
        id="SVGID_00000049901468996166673480000008099267176472231346_"
        x1="2122.595"
        x2="2144.507"
        y1="2829.963"
        y2="2432.162"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000049901468996166673480000008099267176472231346_)"
        d="M2348.458 3216.326c-26.963-147.211-46.602-241.747-46.602-241.747s-298.827-192.782-329.422-145.615-118.268 369.052-118.268 369.052l58.459 15.935s111.757-151.375 121.956-280.61c28.773 94.256 55.586 319.5 276.951 325.962a36.44 36.44 0 0 0 28.699-12.683c7.166-8.339 10.208-19.479 8.227-30.294"
      ></path>
      <linearGradient
        id="SVGID_00000027594645792710587380000005429440791502539148_"
        x1="1656.372"
        x2="1688.284"
        y1="3165.53"
        y2="3277.489"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000027594645792710587380000005429440791502539148_)"
        d="m1702.498 3212.677-35.695 33.782-28.045-28.046 36.969-42.705z"
      ></path>
      <linearGradient
        id="SVGID_00000078003724174258463800000017809670144840042383_"
        x1="2969.354"
        x2="2984.732"
        y1="2631.915"
        y2="2493.512"
        gradientTransform="matrix(-1 0 0 1 5432.43 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000078003724174258463800000017809670144840042383_)"
        d="m2487.981 2479.486 29.804 109.833c1.366 5.032-.321 10.481-4.244 13.45-14.35 10.859-51.853 32.454-103.301 11.467-5.884-2.4-9.916-8.472-10.426-15.379l-7.065-95.682z"
      ></path>
      <linearGradient
        id="SVGID_00000005225656346524832490000007691878118617055148_"
        x1="2959.629"
        x2="2898.404"
        y1="2534"
        y2="2332.063"
        gradientTransform="scale(-1 1)rotate(-4.876 -35.217 65215.797)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000005225656346524832490000007691878118617055148_)"
        d="M2313.534 2390.892s5.843 103.917 42.589 138.854 115.616 17.719 148.172-57.468l-40.424-136.752z"
      ></path>
      <linearGradient
        id="SVGID_00000141427037027448020990000012618582615759019153_"
        x1="2913.913"
        x2="2970.942"
        y1="2170.876"
        y2="2496.152"
        gradientTransform="scale(-1 1)rotate(-4.876 -35.217 65215.797)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#E1473D"></stop>
        <stop offset="1" stopColor="#E9605A"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000141427037027448020990000012618582615759019153_)"
        d="M2452.194 2428.673c-3.082.711-6.059-1.479-6.262-4.636-.615-9.599-2.569-28.659-8.674-42.048 0 0 4.913-13.149-34.629 3.974-39.542 17.124-98.876 42.172-130.789 21.52-31.114-20.135-56.68-110.29 28.082-86.76 3.225.896 6.461-1.459 6.5-4.805.166-14.445 4.407-47.347 38.753-55.094 32.141-7.25 48.068 8.339 54.401 17.468 1.863 2.686 5.722 2.965 7.923.548 10.419-11.44 38.1-35.318 74.17-15.604 29.71 16.238 32.889 35.662 31.369 47.468-.492 3.825 3.182 6.759 6.809 5.449 9.826-3.548 25.226-4.513 38.237 14.982 17.987 26.951-12.659 46.378-21.782 51.378-1.495.819-2.507 2.302-2.662 4-1.201 13.116-5.797 71.565-37.24 101.406-2.723 2.584-9.574-.01-10.727-3.582l-17.6-54.515a5.125 5.125 0 0 0-6.028-3.42z"
      ></path>
      <linearGradient
        id="SVGID_00000007427648504997298600000007454296034613720456_"
        x1="2909.012"
        x2="2847.786"
        y1="2558.158"
        y2="2356.22"
        gradientTransform="scale(-1 1)rotate(-4.876 -35.217 65215.797)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000007427648504997298600000007454296034613720456_)"
        d="M2447.841 2436.132s18.176-53.79 37.145-34.204-10.212 56.643-34.324 62.124z"
      ></path>
      <linearGradient
        id="SVGID_00000018203044784179258870000005869084274296854920_"
        x1="2126.648"
        x2="2301.124"
        y1="2495.521"
        y2="3107.642"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000018203044784179258870000005869084274296854920_)"
        d="M2313.534 2774.786s-136.177 57.366-161.672 28.045-11.473-193.74-11.473-193.74l-57.365-15.326s-70.539 290.228 49.292 333.571c86.992 31.465 252.834-70.963 252.834-70.963z"
      ></path>
      <linearGradient
        id="SVGID_00000000929578683150353150000010187062931883152815_"
        x1="2701.994"
        x2="2457.584"
        y1="2339.527"
        y2="2823.291"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FF928E"></stop>
        <stop offset="1" stopColor="#FE7062"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000000929578683150353150000010187062931883152815_)"
        d="M2441.585 2576.343c-21.956 4.827-46.022 25.796-64.847 45.913-21.498 22.973-35.897 51.769-41.261 82.772l-.523 3.035c-47.391 51.943-89.134 85.571-117.215 98.001 49.292 70.01 75.962 96.606 75.962 96.606s5.684-1.223 15.218-3.685c-9.265 83.539-14.281 148.22-14.281 148.22s96.284 95.571 212.89 122.766c106.198-78.187 122.441-301.859 122.441-301.859 8.317-265.29-114.209-308.077-188.384-291.769"
      ></path>
      <linearGradient
        id="SVGID_00000085239114876405089240000002783656881374236856_"
        x1="2052.404"
        x2="2060.831"
        y1="2363.636"
        y2="2584.449"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000085239114876405089240000002783656881374236856_)"
        d="M2507.529 3169.971s-47.805 142.139-283.755 62.465c-114.406-38.632-274.604-262.607-274.604-262.607s-39.519 108.357-246.035 253.684l-45.892-41.219s209.066-360.341 316.148-322.948 566.857 218.415 534.138 310.625"
      ></path>
      <linearGradient
        id="SVGID_00000032612660637116652730000006847124220964476300_"
        x1="1597.18"
        x2="1626.944"
        y1="3201.183"
        y2="3305.602"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#09005D"></stop>
        <stop offset="1" stopColor="#1A0F91"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000032612660637116652730000006847124220964476300_)"
        d="M1684.209 3229.985c-8.728.099-45.451-16.034-45.451-16.034l-34.207 9.136c-29.814-4.587-55.42 5.565-72.676 15.883a9.836 9.836 0 0 0-4.444 10.985 9.84 9.84 0 0 0 9.338 7.292c33.762.559 89.204 1.123 117.286-.558 42.068-2.517 48.443-1.307 30.154-26.704"
      ></path>
      <linearGradient
        id="SVGID_00000039829573196363485430000004917187268049459890_"
        x1="2354.487"
        x2="2528.683"
        y1="2812.515"
        y2="2812.515"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#E1473D"></stop>
        <stop offset="1" stopColor="#E9605A"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000039829573196363485430000004917187268049459890_)"
        d="M2355.994 2886.503a1.509 1.509 0 0 1-.359-2.972c.36-.09 36.541-9.144 75.425-31.373 35.768-20.448 81.221-56.56 94.647-112.473a1.509 1.509 0 0 1 2.933.703c-13.684 56.987-59.84 93.678-96.15 114.425-39.244 22.422-75.77 31.555-76.135 31.645a1.4 1.4 0 0 1-.361.045"
      ></path>
      <linearGradient
        id="SVGID_00000052091956797025149340000018157653068604753290_"
        x1="2183.052"
        x2="2151.588"
        y1="2091.589"
        y2="2302.85"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4F52FF"></stop>
        <stop offset="1" stopColor="#4042E2"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000052091956797025149340000018157653068604753290_)"
        d="M2052.712 2460.91a9.115 9.115 0 0 1 6.785-12.271l66.456-12.68a5.16 5.16 0 0 1 5.897 3.541l51.117 164.79a2.787 2.787 0 0 1-1.862 3.496l-55.556 16.676a9.12 9.12 0 0 1-11.115-5.417z"
      ></path>
      <path
        fill="#91B3FA"
        d="M2073.675 2462.541a5.159 5.159 0 0 1 3.978-6.891l44.116-7.446a5.16 5.16 0 0 1 5.79 3.564l43.409 140.386a2.79 2.79 0 0 1-2.012 3.535l-39.527 9.508a2.79 2.79 0 0 1-3.265-1.737zM2094.951 2448.949c.109.517.995.767 1.978.56l14.503-3.058c.983-.207 1.692-.794 1.583-1.311l-.106-.502c-.109-.517-.994-.767-1.978-.56l-14.503 3.059c-.983.207-1.692.794-1.583 1.311z"
      ></path>
      <linearGradient
        id="SVGID_00000029742574014462600860000009518581752109302712_"
        x1="2094.236"
        x2="2127.381"
        y1="2606.029"
        y2="2937.476"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FEBBBA"></stop>
        <stop offset="1" stopColor="#FF928E"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000029742574014462600860000009518581752109302712_)"
        d="M2082.285 2596.929c-.855-7.307-25.395-53.837-15.834-71.684s13.385-48.442 22.628-39.837-2.231 38.563 1.912 47.486c4.143 8.924 20.056 31.597 22.139 49.262s13.874 42.842-10.984 46.348-19.861-31.575-19.861-31.575"
      ></path>
      <linearGradient
        id="SVGID_00000076600066650317927790000002614743338032103859_"
        x1="2343.485"
        x2="2452.31"
        y1="2658.238"
        y2="2658.238"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#E1473D"></stop>
        <stop offset="1" stopColor="#E9605A"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000076600066650317927790000002614743338032103859_)"
        d="M2344.991 2702.356a1.505 1.505 0 0 1-1.242-2.36c2.813-4.102 69.333-100.251 107.64-84.035a1.51 1.51 0 0 1 .8 1.978 1.513 1.513 0 0 1-1.978.8c-36.052-15.299-103.302 81.977-103.976 82.962a1.5 1.5 0 0 1-1.244.655"
      ></path>
      <path
        fill="#F8A570"
        d="M2195.525 2612.482c-.468 0-.93-.217-1.224-.626-11.637-16.133-4.291-34.534-1.155-42.391.389-.976.823-2.063.968-2.573-1.422-3.187-6.978-19.505-5.284-29.678.392-2.347 1.61-3.064 2.561-3.251 5.234-1.022 16.609 16.255 21.243 23.74a1.506 1.506 0 0 1-.489 2.075 1.5 1.5 0 0 1-2.075-.488c-7.555-12.202-15.762-22.368-18.041-22.368h-.025c.012.039-.11.256-.198.788-1.623 9.737 4.291 26.61 5.134 28.114.534.934.15 1.899-.993 4.76-2.953 7.398-9.868 24.72.8 39.51a1.508 1.508 0 0 1-1.222 2.388m-1.207-45.168.001.003z"
      ></path>
    </g>
  </svg>
  )
}

export default Games
