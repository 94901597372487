import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchProduct } from "../../slices/productSlice";
import "./Product.scss";
import ProductCard from "../../components/ProductCard/ProductCard";
import MainLoader from "../Loading/MainLoader";
import FilterBar from "../../components/FiltersBar/FilterBar";
import { FaFilter } from "react-icons/fa";
import PhoneFilterBar from "../../components/FiltersBar/PhoneFilterBar";

const Product = () => {
  const product = useSelector((state) => state.product);
  const { location } = useSelector((state) => state.user);
  const [category, setCategory] = useState("");
  const [minPrice, setMinPrice] = useState("0");
  const [maxPrice, setMaxPrice] = useState("1000");
  const [range, setRange] = useState("50");
  const [phoneFilter, setPhoneFilter] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchProductData();
  }, [location, category, range, minPrice, maxPrice]);

  const fetchProductData = async () => {
    try {
      await dispatch(
        fetchProduct({
          lat: location?.lat,
          lon: location?.lon,
          category: category,
          range: range,
          minPrice: minPrice,
          maxPrice: maxPrice,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const phoneFilterHandler = () => {
    if (phoneFilter) {
      document.body.classList.remove("no-scroll");
      setPhoneFilter(false);
      return;
    }
    setPhoneFilter(!phoneFilter);
    document.body.classList.add("no-scroll");
  };
  return (
    <div className="product-main">
      {product?.status === "loading" && <MainLoader />}
      <NavBar />
      <div className="phone-filter-header" onClick={phoneFilterHandler}>
        <FaFilter />
        <p>Apply Filters</p>
      </div>
      {phoneFilter && (
        <PhoneFilterBar
          category={category}
          setCategory={setCategory}
          range={range}
          setRange={setRange}
          minPrice={minPrice}
          setMinPrice={setMinPrice}
          maxPrice={maxPrice}
          setMaxPrice={setMaxPrice}
          phoneFilterHandler={phoneFilterHandler}
        />
      )}
      <FilterBar
        category={category}
        setCategory={setCategory}
        range={range}
        setRange={setRange}
        minPrice={minPrice}
        setMinPrice={setMinPrice}
        maxPrice={maxPrice}
        setMaxPrice={setMaxPrice}
      />
      <div className="product-cont">
        {product?.product &&
          product?.product?.products?.map((item, index) => (
            <ProductCard key={index} item={item} />
          ))}
        {product?.product && product?.product?.products?.length <= 0 && (
          <div className="No-product">
            <p> No Product Avaliable</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Product;
